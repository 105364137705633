// useNavigateErrorHandler.js
import { useNavigate } from 'react-router-dom';

const useNavigateErrorHandler = () => {
  const navigate = useNavigate();

  const handleApiError = (error) => {
    const errorResponse = error?.response || {};
    const { status, data } = errorResponse;

    const errorCode = data?.errorCode || 'Unknown Error';
    const errorMessage = data?.message || 'An unexpected error occurred.';
    const statusCode = status || 'N/A';
    const errorType = data?.type || 'N/A';
    const timestamp = data?.timestamp || new Date().toISOString();

    // Navigate to the error page with error details
    navigate('/error-page', {
      state: { errorCode, message: errorMessage, statusCode, type: errorType, timestamp },
    });
  };

  return handleApiError;
};

export default useNavigateErrorHandler;
