import { useMemo, useState, useEffect, useCallback, useRef } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import { Icon } from '@iconify/react';
import axios from 'axios';
import { getCurrentProject, getCurrentServer, getMandEndPoint } from 'src/helper/server-address';
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';
// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  tag: <img src="/assets/icons/sidebar/User.png" alt="User Icon" width="52" height="32" />,
  interview: (
    <img src="/assets/icons/sidebar/interview 2.png" alt="Interview Icon" width="52" height="28" />
  ),
  admin: <img src="/assets/icons/sidebar/Admin.png" alt="Admin Icon" width="52" height="28" />,
  client: (
    <img src="/assets/icons/sidebar/clienticon.png" alt="Client Icon" width="52" height="28" />
  ),
  vendor: <img src="/assets/icons/sidebar/Vendor.png" alt="Vendor Icon" width="52" height="28" />,
  superadmin: (
    <img src="/assets/icons/sidebar/SuperAdmin.png" alt="SAdmin Icon" width="62" height="26" />
  ),
  project: (
    <img src="/assets/icons/sidebar/projectmgr.png" alt="Project Icon" width="62" height="28" />
  ),
  masters: (
    <img src="/assets/icons/sidebar/mastersdata.png" alt="Masters Icon" width="62" height="28" />
  ),
  approvals: (
    <img src="/assets/icons/sidebar/Approvals.png" alt="Approvals Icon" width="62" height="26" />
  ),
  inventory: (
    <img src="/assets/icons/sidebar/Inventory.png" alt="Inventory Icon" width="62" height="28" />
  ),
  assets: (
    <img src="/assets/icons/sidebar/assets.png" alt="Inventory Icon" width="62" height="28" />
  ),
  menuitems:(

    <>
    <img src="/assets/icons/menu-items/mdi--eye.png" alt="Eye Icon"  style={{
      margin: '10px',  // Adds spacing outside the image
      padding: '15px',  // Adds spacing inside the image's border
    }}/>
    </>

  ),
  procurement: (
    <img src="/assets/icons/sidebar/procurement.png" alt="Inventory Icon" width="62" height="28" />
  ),
  preferences: (
    <img src="/assets/icons/sidebar/preferences.png" alt="Inventory Icon" width="62" height="28" />
  ),
  todo: (
    <img src="/assets/icons/sidebar/ToDo.png" alt="ToDo Icon" width="62" height="26" />
  ),
  // tag: <Icon icon="grommet-icons:group" width="25" height="20" />,
  // project: <Icon icon="eos-icons:project-outlined" width="26" height="26" />,
  person: <Icon icon="f7:doc-person" width="56" height="56" />,
  // approval: <Icon icon="fluent-mdl2:document-approval" width="20" height="20" />,
  // inventory: <Icon icon="material-symbols-light:inventory-rounded" width="50px" height="50px" />,
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: <Icon icon="la:user-tie" width="32" height="32" />,
  employee: <Icon icon="clarity:employee-group-line" width="26" height="36" />,
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  // calendar: icon('ic_calendar'),
  calendar: <Icon icon="fluent-emoji-flat:calendar" width="56" height="56" />,
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  // const { t } = useLocales();
  const token = sessionStorage.getItem('token');

  const fetchRolesByModule = async (module) => {
    try {
      const response = await axios.get(
        `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/roleConfig/roleOfModule/${module}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(
      //   response.data.map((role) => role.roleName),
      //   'modulerole'
      // );
      return response.data.map((role) => role.roleName);
    } catch (error) {
      console.error('Error fetching roles:', error);
      return [];
    }
  };
  const fetchRolesByScreen = async (screen) => {
    try {
      const response = await axios.get(
        `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/roleConfig/rolesOfScrn/${screen}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(
      //   response.data.map((ur) => ur.roleId.roleName),
      //   'screenrole'
      // );
      return response.data.map((ur) => ur.roleId.roleName);
    } catch (error) {
      console.error('Error fetching roles:', error);
      return [];
    }
  };

  // -------------------------------------------------------------------------------------------------------------------------
  // // code for counting total requisions for Approval Section. Ex.Candidate,Material,Purchase
  // // Retrieve data from sessionStorage
  // const user = JSON.parse(sessionStorage.getItem('user'));

  // // Define states for requisition counts and loading state
  // const [requisitionCount_candidate, setRequisitionCount_candidate] = useState(0);
  // const [requisitionCount_material, setRequisitionCount_material] = useState(0);
  // const [requisitionCount_purchase, setRequisitionCount_purchase] = useState(0);
  // const [totalRequisitionCount, setTotalRequisitionCount] = useState(0);
  // const [loading, setLoading] = useState(true);

  // // Using ref to hold the previous counts and avoid re-rendering the whole component
  // const previousCountsRef = useRef({
  //   candidate: 0,
  //   material: 0,
  //   purchase: 0
  // });

  // // Function to fetch requisition counts
  // const pendingCounter = useCallback(async () => {
  //   if (!user?.userId || !token) return; // Ensure user and token are available

  //   try {
  //     setLoading(true); // Start loading

  //     // Fetch requisition data concurrently using Promise.all
  //     const [
  //       { data: count_candidate },
  //       { data: count_material },
  //       { data: count_purchase }
  //     ] = await Promise.all([
  //       axios.get(
  //         `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/canreqmngappr/count/all-requisitions`,
  //         { headers: { Authorization: `Bearer ${token}` } }
  //       ),
  //       axios.get(
  //         `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/materialmngappr/count/all-requisitions`,
  //         { headers: { Authorization: `Bearer ${token}` } }
  //       ),
  //       axios.get(
  //         `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/purreqmngappr/count/all-requisitions`,
  //         { headers: { Authorization: `Bearer ${token}` } }
  //       ),
  //     ]);

  //     // Only update the state if the count has changed
  //     if (previousCountsRef.current.candidate !== count_candidate) {
  //       setRequisitionCount_candidate(count_candidate);
  //       previousCountsRef.current.candidate = count_candidate;
  //     }

  //     if (previousCountsRef.current.material !== count_material) {
  //       setRequisitionCount_material(count_material);
  //       previousCountsRef.current.material = count_material;
  //     }

  //     if (previousCountsRef.current.purchase !== count_purchase) {
  //       setRequisitionCount_purchase(count_purchase);
  //       previousCountsRef.current.purchase = count_purchase;
  //     }

  //     // Set the total requisition count
  //     const totalCount = count_candidate + count_material + count_purchase;
  //     setTotalRequisitionCount(totalCount);

  //   } catch (error) {
  //     console.error('Error fetching requisition data:', error);
  //   } finally {
  //     setLoading(false); // Stop loading
  //   }
  // }, [user?.userId, token]);

  // useEffect(() => {
  //   if (token && user?.userId) {
  //     // Fetch data immediately on mount
  //     pendingCounter();

  //     // Poll every 1 second
  //     const intervalId = setInterval(pendingCounter, 1000);

  //     // Cleanup interval on component unmount
  //     return () => clearInterval(intervalId);
  //   }

  //   return undefined; // Ensure a return statement is included when conditions are not met
  // }, [pendingCounter, token, user?.userId]);

  // // Effect to log updated counts for debugging purposes
  // useEffect(() => {
  //   console.log('Updated Counts:', {
  //     requisitionCount_candidate,
  //     requisitionCount_material,
  //     requisitionCount_purchase,
  //     totalRequisitionCount,
  //   });
  // }, [requisitionCount_candidate, requisitionCount_material, requisitionCount_purchase, totalRequisitionCount]);

  // Retrieve data from sessionStorage
  const user = JSON.parse(sessionStorage.getItem('user'));

  // Define states for requisition counts and loading state
  const [requisitionCount_candidate, setRequisitionCount_candidate] = useState(0);
  const [requisitionCount_cancelCandidate, setRequisitionCount_cancelCandidate] = useState(0);
  const [requisitionCount_material, setRequisitionCount_material] = useState(0);
  const [requisitionCount_purchase, setRequisitionCount_purchase] = useState(0);
  const [totalRequisitionCount, setTotalRequisitionCount] = useState(0);
  const [loading, setLoading] = useState(true);

  // Using ref to hold the previous counts and avoid re-rendering the whole component
  const previousCountsRef = useRef({
    candidate: 0,
    cancelCandidate: 0,
    material: 0,
    purchase: 0,
  });

  // Function to fetch requisition counts
  const pendingCounter = useCallback(async () => {
    if (!user?.userId || !token) return; // Ensure user and token are available

    try {
      setLoading(true); // Start loading

      // Fetch requisition data concurrently using Promise.all
      const [
        { data: count_candidate },
        { data: count_cancelCan },
        { data: count_material },
        { data: count_purchase },
      ] = await Promise.all([
        axios.get(
          `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/canreqmngappr/count/all-requisitions`,
          { headers: { Authorization: `Bearer ${token}` } }
        ),
        axios.get(
          `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/canreqcancellation/count/all-requisitions`,
          { headers: { Authorization: `Bearer ${token}` } }
        ),
        axios.get(
          `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/materialmngappr/count/all-requisitions`,
          { headers: { Authorization: `Bearer ${token}` } }
        ),
        axios.get(
          `${getCurrentServer()}/${getCurrentProject()}/${getMandEndPoint()}/purreqmngappr/count/all-requisitions`,
          { headers: { Authorization: `Bearer ${token}` } }
        ),
      ]);

      // Only update the state if the count has changed
      if (previousCountsRef.current.candidate !== count_candidate) {
        setRequisitionCount_candidate(count_candidate);
        previousCountsRef.current.candidate = count_candidate;
      }

      if (previousCountsRef.current.cancelCandidate !== count_cancelCan) {
        setRequisitionCount_cancelCandidate(count_cancelCan);
        previousCountsRef.current.cancelCandidate = count_cancelCan;
      }

      if (previousCountsRef.current.material !== count_material) {
        setRequisitionCount_material(count_material);
        previousCountsRef.current.material = count_material;
      }

      if (previousCountsRef.current.purchase !== count_purchase) {
        setRequisitionCount_purchase(count_purchase);
        previousCountsRef.current.purchase = count_purchase;
      }

      // Set the total requisition count
      const totalCount = count_candidate + count_cancelCan + count_material + count_purchase;
      setTotalRequisitionCount(totalCount);
    } catch (error) {
      console.error('Error fetching requisition data:', error);
    } finally {
      setLoading(false); // Stop loading
    }
  }, [user?.userId, token]);

  // Use effect to trigger pendingCounter once on mount and avoid unnecessary polling
  useEffect(() => {
    if (token && user?.userId) {
      // Fetch data immediately on mount
      pendingCounter();
    }
  }, [pendingCounter, token, user?.userId]);

  // Effect to log updated counts for debugging purposes
  useEffect(() => {
    console.log('Updated Counts:', {
      requisitionCount_candidate,
      requisitionCount_material,
      requisitionCount_purchase,
      requisitionCount_cancelCandidate,
      totalRequisitionCount,
    });
  }, [
    requisitionCount_candidate,
    requisitionCount_material,
    requisitionCount_purchase,
    requisitionCount_cancelCandidate,
    totalRequisitionCount,
  ]);

  const data = useMemo(
    () => [
      {
        subheader: '',
        items: [
          {
            title: 'Talent Acquisition (TA)',
            path: paths.dashboard.talentAcquisition.root,
            icon: ICONS.tag,
            roles: fetchRolesByModule('Talent Aquisition'),
            children: [
              {
                title: 'Tag',
                path: paths.dashboard.general.tag,
              },
              // {
              //   title: 'Candidate Requisitions',
              //   path: paths.dashboard.talentAcquisition.RequirementList,
              // },
              {
                title: 'Candidate Requisition',
                path: paths.dashboard.talentAcquisition.CanReqTagList,
                roles: fetchRolesByScreen('Candidate Requisition'),
              },
              {
                title: 'Manager Requisitions',
                path: paths.dashboard.talentAcquisition.CanReqManagerList,
                roles: fetchRolesByScreen('Manager Requisitions'),
              },
              // { title: 'Manager Requisitions Status', path: paths.dashboard.talentAcquisition.CandidateStatusViewMgrList },
              {
                title: 'Schedule Interview',
                path: paths.dashboard.talentAcquisition.listAndCalendarView,
                roles: fetchRolesByScreen('Schedule Interview'),
              },
              // { title: 'Requisition Status', path: paths.dashboard.talentAcquisition.CanReqTagList },
              // {
              //   title: 'Offer Letter',
              //   path: paths.dashboard.talentAcquisition.OfferLetter,
              //   roles: fetchRolesByScreen('Offer Letter'),
              // },
              {
                title: 'Offered Candidates',
                path: paths.dashboard.talentAcquisition.OfferedCandidates,
                roles: fetchRolesByScreen('Offered Candidates'),
              },
              {
                title: 'Selected Candidates',
                path: paths.dashboard.talentAcquisition.SelectedCandidates,
                roles: fetchRolesByScreen('Selected Candidates'),
              },
              {
                title: 'Candidates',
                path: paths.dashboard.talentAcquisition.master.ListCandidate,
                roles: fetchRolesByScreen('Candidates'),
              },
              {
                title: 'Job Description (JD)',
                path: paths.dashboard.talentAcquisition.master.PastJd,
                roles: fetchRolesByScreen('Job Description'),
              },
              {
                title: 'My Interviews',
                path: paths.dashboard.talentAcquisition.ListInterview,
                roles: fetchRolesByScreen('My Interviews'),
              },
              {
                title: 'Report',
                path: paths.dashboard.talentAcquisition.master.root,
                children: [
                  { title: 'report 1', path: paths.dashboard.general.banking },
                  { title: 'report 2', path: paths.dashboard.general.analytics },
                ],
              },
            ],
          },
          // Tag Dashboard
          // {
          //   title: 'Tag',
          //   path: paths.dashboard.general.tag,
          //   icon: ICONS.user,
          // },
          // User Master for User Registration
          // {
          //   title: 'User',
          //   path: paths.dashboard.master.usermst,
          //   icon: ICONS.person,
          // },
        ],
      },
      {
        subheader: '',
        items: [
          {
            title: 'Human Resources (HR)',
            path: paths.dashboard.humanResources.root,
            icon: ICONS.interview,
            roles: fetchRolesByModule('Human Resources'),
            children: [
              {
                title: 'Employee',
                path: paths.dashboard.humanResources.ListEmployee,
                roles: fetchRolesByScreen('Employee'),
              },
            ],
          },
        ],
      },
      {
        subheader: '',
        items: [
          {
            title: 'Inventory',
            path: paths.dashboard.inventory.root,
            icon: ICONS.inventory,
            roles: fetchRolesByModule('Inventory'),
            children: [
              {
                title: 'Material Requisition',
                path: paths.dashboard.inventory.ItemRequisition,
                roles: fetchRolesByScreen('Item Requisition'),
              },

              {
                title: 'Inventory Transactions',
                path: paths.dashboard.inventory.InvTransactionList,
                roles: fetchRolesByScreen('Inventory Transactions'),
              },
              {
                title: 'On Hand Quantity',
                path: paths.dashboard.inventory.InvOnHandList,
                roles: fetchRolesByScreen('On Hand Quantity'),
              },
              {
                title: 'Physical inventory',
                path: paths.dashboard.inventory.AllPhysicalInventoryList,
                roles: fetchRolesByScreen('Physical Inventory'),
              },
              {
                title: 'Material inspection',
                path: paths.dashboard.inventory.InspectionList,
                roles: fetchRolesByScreen('Material Inspection'),
              },
              {
                title: 'Item',
                path: paths.dashboard.inventory.master.ItemList,
                roles: fetchRolesByScreen('Item'),
              },
              {
                title: 'Approved Item Requisition',
                path: paths.dashboard.inventory.AllItemReqTag,
                roles: fetchRolesByScreen('Approved Item Requisition'),
              },
              {
                title: 'Report',
                path: paths.dashboard.user.root,
                children: [
                  { title: 'report 1', path: paths.dashboard.invoice.root },
                  { title: 'report 2', path: paths.dashboard.invoice.demo.edit },
                ],
              },
            ],
          },
        ],
      },
      {
        subheader: '',
        items: [
          {
            title: 'Assets',
            path: paths.dashboard.assets.root,
            icon: ICONS.assets,
            roles: fetchRolesByModule('Assets'),
            children: [
              {
                title: 'My Assets',
                path: paths.dashboard.assets.AssetTrackerList,
                roles: fetchRolesByScreen('My Assets'),
              },
              {
                title: 'Asset Tracker',
                path: paths.dashboard.assets.AssetTrackerAdminList,
                roles: fetchRolesByScreen('Asset Tracker'),
              },
              {
                title: 'Reserved Assets',
                path: paths.dashboard.assets.ReservedAssets,
                roles: fetchRolesByScreen('Reserved Assets'),
              },
            ],
          },
        ],
      },
      {
        items: [
          {
            title: 'User Management',
            path: paths.dashboard.superAdmin.root,
            icon: ICONS.superadmin,
            roles: fetchRolesByModule('User Management'),
            children: [
              {
                title: 'Role Permission',
                path: paths.dashboard.superAdmin.rolePermission,
                roles: fetchRolesByScreen('Role Permission'),
              },
              {
                title: 'User Role Assignment',
                path: paths.dashboard.superAdmin.UserRoleAssign,
                roles: fetchRolesByScreen('User Role Assignment'),
              },
              {
                title: 'User',
                path: paths.dashboard.superAdmin.usermst,
                roles: fetchRolesByScreen('User'),
              },
            ],
          },
        ],
      },
      {
        items: [
          {
            title: 'Approvals',
            path: paths.dashboard.ManagerApproval.root,
            icon: ICONS.approvals,
            roles: fetchRolesByModule('Approvals'),
            // children: [
            //   {
            //     title: 'Candidate Requisitions',
            //     path: paths.dashboard.ManagerApproval.CanReqApproval,
            //     roles: fetchRolesByScreen('Candidate Requisitions Approval'),
            //     count:PendingCount_candidate
            //   },
            //   {
            //     title: 'Material Requisitions',
            //     path: paths.dashboard.ManagerApproval.MaterialReqApproval,
            //     roles: fetchRolesByScreen('Material Requisitions Approvals'),
            //     count:PendingCount_material
            //   },
            //   {
            //     title: 'Purchase Requisitions',
            //     path: paths.dashboard.ManagerApproval.PurReqApproval,
            //     roles: fetchRolesByScreen('Purchase Requisitions Approval'),
            //     count:PendingCount_purchase
            //   },
            // ],
            // children: [
            //   {
            //     title: `Candidate Requisitions (${PendingCount_candidate})`,
            //     path: paths.dashboard.ManagerApproval.CanReqApproval,
            //     roles: fetchRolesByScreen('Candidate Requisitions Approval'),
            //     count: PendingCount_candidate,  // Pass the count here
            //   },
            //   {
            //     title: `Material Requisitions (${PendingCount_material})`,
            //     path: paths.dashboard.ManagerApproval.MaterialReqApproval,
            //     roles: fetchRolesByScreen('Material Requisitions Approvals'),
            //     count: PendingCount_material,  // Pass the count here
            //   },
            //   {
            //     title: `Purchase Requisitions (${PendingCount_purchase})`,
            //     path: paths.dashboard.ManagerApproval.PurReqApproval,
            //     roles: fetchRolesByScreen('Purchase Requisitions Approval'),
            //     count: PendingCount_purchase,  // Pass the count here
            //   },
            // ],
            children: [
              {
                title: `Candidate Requisitions`,
                path: paths.dashboard.ManagerApproval.root,
                roles: fetchRolesByScreen('Candidate Requisitions Approval'),

                children: [
                  {
                    title: 'Candidate Requisitions',
                    path: paths.dashboard.ManagerApproval.CandidateRequisitionApproval
                      .CanReqApproval,
                    roles: fetchRolesByScreen('Candidate Requisitions Approval'),
                    info: <Label color="info">{requisitionCount_candidate}</Label>,
                  },
                  {
                    title: 'Cancellation Requisitions',
                    path: paths.dashboard.ManagerApproval.CandidateRequisitionApproval.CancelCanReq,
                    roles: fetchRolesByScreen('Candidate Requisitions Approval'),
                    info: <Label color="info">{requisitionCount_cancelCandidate}</Label>,
                  },
                ],
              },
              {
                title: `Material Requisitions`,
                path: paths.dashboard.ManagerApproval.MaterialReqApproval,
                roles: fetchRolesByScreen('Material Requisitions Approvals'),
                info: <Label color="info">{requisitionCount_material}</Label>,
              },
              {
                title: `Purchase Requisitions`,
                path: paths.dashboard.ManagerApproval.PurReqApproval,
                roles: fetchRolesByScreen('Purchase Requisitions Approval'),
                info: <Label color="info">{requisitionCount_purchase}</Label>,
              },
            ],
          },
        ],
      },
      {
        subheader: '',
        items: [
          {
            title: 'Preferences',
            path: paths.dashboard.preferences.root,
            icon: ICONS.preferences,
            roles: fetchRolesByModule('Preferences'),
            children: [
              {
                title: 'Preferences',
                path: paths.dashboard.preferences.TenantConfig,
                roles: fetchRolesByScreen('Preferences'),
              },
            ],
          },
        ],
      },
      {
        subheader: '',
        items: [
          {
            title: 'Procurement',
            path: paths.dashboard.procurement.root,
            icon: ICONS.procurement,
            roles: fetchRolesByModule('Procurement'),
            children: [
              {
                title: 'Approved Purchase Requisitions',
                path: paths.dashboard.procurement.PurchaseRequisition,
                roles: fetchRolesByScreen('Purchase Requisitions'),
              },
            ],
          },
        ],
      },
      {
        subheader: '',
        items: [
          {
            title: 'Project',
            path: paths.dashboard.project.roots,
            icon: ICONS.project,
            roles: fetchRolesByModule('Project'),
            children: [
              {
                title: 'Project',
                path: paths.dashboard.project.projectlist,
                roles: fetchRolesByScreen('Project'),
              },
              {
                title: 'Project Allocation',
                path: paths.dashboard.project.projectallocationform,
                roles: fetchRolesByScreen('Project Allocation'),
              },
            ],
          },
        ],
      },
      {
        subheader: '',
        items: [
          {
            title: 'Master Data',
            path: paths.dashboard.masters.root,
            icon: ICONS.masters,
            roles: fetchRolesByModule('Master Data'),
            children: [
              {
                title: 'Global Masters',
                path: paths.dashboard.masters.globalMasters.root,
                roles: fetchRolesByModule('Master Data'),
                children: [
                  {
                    title: 'Address Purpose',
                    path: paths.dashboard.masters.globalMasters.AddressPurpose,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Bank Account Type',
                    path: paths.dashboard.masters.globalMasters.BankAccountType,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Bank Name',
                    path: paths.dashboard.masters.globalMasters.BankName,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Blood Group',
                    path: paths.dashboard.masters.globalMasters.BloodGroup,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Business Unit',
                    path: paths.dashboard.masters.globalMasters.BusinessUnit,
                    roles: fetchRolesByScreen('Master'),
                  },
                  // {
                  //   title: 'Certificate Types',
                  //   path: paths.dashboard.masters.globalMasters.CertificateType,
                  //   roles: fetchRolesByScreen('Master'),
                  // },
                  {
                    title: 'Certificate',
                    path: paths.dashboard.masters.globalMasters.Certificates,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Contract Type',
                    path: paths.dashboard.masters.globalMasters.ContractType,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Delivery Unit',
                    path: paths.dashboard.masters.globalMasters.DeliveryUnit,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Department',
                    path: paths.dashboard.masters.globalMasters.Department,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Designation',
                    path: paths.dashboard.masters.globalMasters.Designation,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Domain',
                    path: paths.dashboard.masters.globalMasters.Domain,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Employement Type',
                    path: paths.dashboard.masters.globalMasters.EmployementType,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Gender',
                    path: paths.dashboard.masters.globalMasters.Gender,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Good',
                    path: paths.dashboard.masters.globalMasters.Good,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'HSN/SAC',
                    path: paths.dashboard.masters.globalMasters.HsnSac,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Institute',
                    path: paths.dashboard.masters.globalMasters.Institute,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Interview Level',
                    path: paths.dashboard.masters.globalMasters.InterviewLevel,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Interview Status',
                    path: paths.dashboard.masters.globalMasters.InterviewStatus,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Item Category',
                    path: paths.dashboard.masters.globalMasters.ItemCategoryMst,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Item Condition',
                    path: paths.dashboard.masters.globalMasters.ItemCondition,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Item Type',
                    path: paths.dashboard.masters.globalMasters.ItemType,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Language',
                    path: paths.dashboard.masters.globalMasters.Language,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Marital Status',
                    path: paths.dashboard.masters.globalMasters.MaritalStatus,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Meeting Platform',
                    path: paths.dashboard.masters.globalMasters.MeetingPlatform,
                    roles: fetchRolesByScreen('Master'),
                  },

                  {
                    title: 'Organization Type',
                    path: paths.dashboard.masters.globalMasters.OrganizationType,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Position Level',
                    path: paths.dashboard.masters.globalMasters.PositionLevel,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Practice',
                    path: paths.dashboard.masters.globalMasters.Practice,
                    roles: fetchRolesByScreen('Master'),
                  },
                  // {
                  //   title: 'Project Status',
                  //   path: paths.dashboard.masters.globalMasters.ProjectStatus,
                  //   roles: fetchRolesByScreen('Master'),
                  // },
                  {
                    title: 'Project Type',
                    path: paths.dashboard.masters.globalMasters.PTList,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Qualification',
                    path: paths.dashboard.masters.globalMasters.Qualification,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Qualification Stream',
                    path: paths.dashboard.masters.globalMasters.QualificationStreams,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Qualification Type',
                    path: paths.dashboard.masters.globalMasters.QualificationType,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'New Employee Question',
                    path: paths.dashboard.masters.globalMasters.Questions,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Reason',
                    path: paths.dashboard.masters.globalMasters.Reason,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Relationship',
                    path: paths.dashboard.masters.globalMasters.Relationship,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Religion',
                    path: paths.dashboard.masters.globalMasters.Religion,
                    roles: fetchRolesByScreen('Master'),
                  },
                  // {
                  //   title: 'Requisition Status',
                  //   path: paths.dashboard.masters.globalMasters.ReqStatus,
                  //   roles: fetchRolesByScreen('Master'),
                  // },
                  {
                    title: 'Revenue Type',
                    path: paths.dashboard.masters.globalMasters.RevenueType,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Role',
                    path: paths.dashboard.masters.globalMasters.Role,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Salutation',
                    path: paths.dashboard.masters.globalMasters.NameTitle,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Service Category',
                    path: paths.dashboard.masters.globalMasters.ServiceCategory,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Skill',
                    path: paths.dashboard.masters.globalMasters.SkillList,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Skill Category',
                    path: paths.dashboard.masters.globalMasters.SkillCategory,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Source Of Hire',
                    path: paths.dashboard.masters.globalMasters.SourceOfHire,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'TDS Section',
                    path: paths.dashboard.masters.globalMasters.TDSSection,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Inventory Transaction Type',
                    path: paths.dashboard.masters.globalMasters.TransactionType,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'University',
                    path: paths.dashboard.masters.globalMasters.University,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Unique Identity Document',
                    path: paths.dashboard.masters.globalMasters.UniqueIdentityDoc,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Unit Of Measure',
                    path: paths.dashboard.masters.globalMasters.UOM,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Visa Name',
                    path: paths.dashboard.masters.globalMasters.VisaName,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Visa Type',
                    path: paths.dashboard.masters.globalMasters.VisaType,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Work Mode',
                    path: paths.dashboard.masters.globalMasters.WorkMode,
                    roles: fetchRolesByScreen('Master'),
                  },
                ],
              },
              {
                title: 'Local Masters',
                path: paths.dashboard.masters.localMasters.root,
                roles: fetchRolesByModule('Master Data'),
                children: [
                  {
                    title: 'Offer Decline Reason',
                    path: paths.dashboard.masters.localMasters.DeclineReason,
                    roles: fetchRolesByScreen('Master'),
                  },

                  {
                    title: 'Offer Withdraw Reason',
                    path: paths.dashboard.masters.localMasters.OfferWithdrawReason,
                    roles: fetchRolesByScreen('Master'),
                  },

                  {
                    title: 'Employee Grade',
                    path: paths.dashboard.masters.localMasters.EmpGrade,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Inspection Parameter',
                    path: paths.dashboard.masters.localMasters.InspectionParameter,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Interview Cancel Reason',
                    path: paths.dashboard.masters.localMasters.InterviewCancelReason,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Module',
                    path: paths.dashboard.masters.localMasters.Modules,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Negative Feedback',
                    path: paths.dashboard.masters.localMasters.NegativeFeedback,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Recommendation',
                    path: paths.dashboard.masters.localMasters.Recommendation,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Report',
                    path: paths.dashboard.masters.localMasters.Reports,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Role Level',
                    path: paths.dashboard.masters.localMasters.RoleLevel,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Screen',
                    path: paths.dashboard.masters.localMasters.Screens,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Service',
                    path: paths.dashboard.masters.localMasters.Service,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Service Plan',
                    path: paths.dashboard.masters.localMasters.ServicePlan,
                    roles: fetchRolesByScreen('Master'),
                  },
                  {
                    title: 'Sub Inventory',
                    path: paths.dashboard.masters.localMasters.SubInventory,
                    roles: fetchRolesByScreen('Master'),
                  },
                ],
              },
              {
                title: 'Client',
                path: paths.dashboard.masters.ListClient,
                roles: fetchRolesByScreen('Client'),
              },
              {
                title: 'Vendor',
                path: paths.dashboard.masters.ListVendor,
                roles: fetchRolesByScreen('Vendor'),
              },
            ],
          },
        ],
      },

// My Requisitions by Role

{
  items: [
    {
      title: 'My Requisitions',
      path: paths.dashboard.MyRequisitions.root,
      icon: ICONS.approvals,    
      roles: fetchRolesByModule('My Requisitions'),

      children: [
        {
          
          title: `Candidate Requisitions`,
          path: paths.dashboard.MyRequisitions.CanReqManagerList,
          roles: fetchRolesByScreen('Manager Requisitions'),
      
        },
        {
          title: `Material Requisitions`,
          path: paths.dashboard.MyRequisitions.ItemRequisition,
          roles: fetchRolesByScreen('Item Requisition'),
        },

        {
          title: 'Purchase Requisitions',
          path: paths.dashboard.MyRequisitions.AllItemReqTag,
          roles: fetchRolesByScreen('Approved Item Requisition'),
        },

        // {
        //   title: 'Purchase Requisitions',
        //   path: paths.dashboard.MyRequisitions.PR,
        //   roles: fetchRolesByScreen('My Requisitions'),
        // },
      ],
    },
  ],
},

      
{
  items: [
    {
      title: 'To Do',
      path: paths.dashboard.ToDo.root,
      icon: ICONS.todo,    
      roles: fetchRolesByModule('To Do'),
      children: [
        {
          title: 'To Do',
          path: paths.dashboard.ToDo.TagToDo,   
          roles: fetchRolesByModule('To Do'),
        },       
      ],
    },
  ],
},



    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      requisitionCount_candidate,
      requisitionCount_purchase,
      requisitionCount_material,
      requisitionCount_cancelCandidate,
    ]
  );

  return data;
}

// ***********************************************************************************************************************************************************************************************

// import { useMemo } from 'react';
// // routes
// import { paths } from 'src/routes/paths';
// // locales
// import { useLocales } from 'src/locales';
// // components
// import { Icon } from '@iconify/react';
// import Label from 'src/components/label';
// import Iconify from 'src/components/iconify';
// import SvgColor from 'src/components/svg-color';

// // ----------------------------------------------------------------------

// const icon = (name) => (
//   <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
//   // OR
//   // <Iconify icon="fluent:mail-24-filled" />
//   // https://icon-sets.iconify.design/solar/
//   // https://www.streamlinehq.com/icons
// );

// const ICONS = {
//   tag: <Icon icon="grommet-icons:group" width="25" height="25" />,
//   project: <Icon icon="eos-icons:project-outlined" color="#637381" width="26" height="26" />,
//   person: <Icon icon="ic:outline-person" color="#637381" width="26" height="26" />,
//   job: icon('ic_job'),
//   blog: icon('ic_blog'),
//   chat: icon('ic_chat'),
//   mail: icon('ic_mail'),
//   user: icon('ic_user'),
//   file: icon('ic_file'),
//   lock: icon('ic_lock'),
//   tour: icon('ic_tour'),
//   order: icon('ic_order'),
//   label: icon('ic_label'),
//   blank: icon('ic_blank'),
//   kanban: icon('ic_kanban'),
//   folder: icon('ic_folder'),
//   banking: icon('ic_banking'),
//   booking: icon('ic_booking'),
//   invoice: icon('ic_invoice'),
//   product: icon('ic_product'),
//   calendar: icon('ic_calendar'),
//   disabled: icon('ic_disabled'),
//   external: icon('ic_external'),
//   menuItem: icon('ic_menu_item'),
//   ecommerce: icon('ic_ecommerce'),
//   analytics: icon('ic_analytics'),
//   dashboard: icon('ic_dashboard'),
// };

// // ----------------------------------------------------------------------

// export function useNavData() {
//   // const { t } = useLocales();

//   const data = useMemo(
//     () => [
//       {
//         subheader: '',
//         items: [
//           {
//             title: 'Talent Aquisition',
//             path: paths.dashboard.user.root,
//             icon: ICONS.tag,
//             children: [
//               {
//                 title: 'Tag',
//                 path: paths.dashboard.general.tag,
//               },
//               {
//                 title: 'New Requisition',
//                 path: paths.dashboard.CandidateRequirement.RequirementList,
//               },
//               { title: 'Schedule Interview', path: paths.dashboard.calendar },
//               { title: 'Requisition Status', path: paths.dashboard.recruitment.CanReqTagList },
//               { title: 'Offer Letter', path: paths.dashboard.recruitment.OfferLetter },
//               { title: 'Offered candidates', path: paths.dashboard.recruitment.OfferedCandidates },
//               {
//                 title: 'Master',
//                 path: paths.dashboard.user.root,
//                 children: [
//                   { title: 'Candidates', path: paths.dashboard.recruitment.ListCandidate },
//                   { title: 'Job Description', path: paths.dashboard.jd.PastJd },
//                   { title: 'Employee', path: paths.dashboard.employee.ListEmployee },
//                 ],
//               },
//               {
//                 title: 'Report',
//                 path: paths.dashboard.user.root,
//                 children: [
//                   { title: 'report 1', path: paths.dashboard.general.banking },
//                   { title: 'report 2', path: paths.dashboard.general.analytics },
//                 ],
//               },
//             ],
//           },
//           // Tag Dashboard
//           // {
//           //   title: 'Tag',
//           //   path: paths.dashboard.general.tag,
//           //   icon: ICONS.user,
//           // },
//           // User Master for User Registration
//           // {
//           //   title: 'User',
//           //   path: paths.dashboard.master.usermst,
//           //   icon: ICONS.person,
//           // },
//         ],
//       },
//       {
//         items: [
//           {
//             title: 'Interview',
//             path: paths.dashboard.interview.ListInterview,
//             icon: ICONS.user,
//             children: [
//               {
//                 title: 'Interviews',
//                 path: paths.dashboard.interview.ListInterview,
//               },
//               // {
//               //   title: t('result & feedback'),
//               //   path: paths.dashboard.interview.root,
//               // },
//             ],
//           },
//         ],
//       },
//       {
//         items: [
//           {
//             title: 'Admin',
//             path: paths.dashboard.master.usermst,
//             icon: ICONS.user,
//             children: [
//               {
//                 title: 'User',
//                 path: paths.dashboard.master.usermst,
//               },
//               {
//                 title: 'Preferences',
//                 path: paths.dashboard.tenantconfiguration.TenantConfig,
//               },
//             ],
//           },
//         ],
//       },
//       {
//         items: [
//           {
//             title: 'Approvals',
//             path: paths.dashboard.ManagerApproval.CanReqApproval,
//             icon: ICONS.user,
//             children: [
//               {
//                 title: 'Candidate Requisitions',
//                 path: paths.dashboard.ManagerApproval.CanReqApproval,
//               },
//               {
//                 title: 'Material Requisitions',
//                 path: paths.dashboard.ManagerApproval.MaterialReqApproval,
//               },
//             ],
//           },
//         ],
//       },
//       {
//         subheader: '',
//         items: [
//           {
//             title: 'Inventory',
//             path: paths.dashboard.user.root,
//             icon: ICONS.user,
//             children: [
//               { title: 'Item Requisition', path: paths.dashboard.product.ItemRequisition },
//               { title: 'Inventory Transactions', path: paths.dashboard.product.InvTransactionList },
//               { title: 'My Assets', path: paths.dashboard.product.AssetTrackerList },
//               { title: 'Asset Tracker Admin', path: paths.dashboard.product.AssetTrackerAdminList },
//               { title: 'On hand inventory', path: paths.dashboard.product.InvOnHandList },
//               {
//                 title: 'Physical inventory',
//                 path: paths.dashboard.product.AllPhysicalInventoryList,
//               },
//               { title: 'Material inspection', path: paths.dashboard.product.InspectionList },
//               {
//                 title: 'Master',
//                 path: paths.dashboard.user.root,
//                 children: [
//                   { title: 'Item', path: paths.dashboard.product.root },
//                   { title: 'Asset', path: paths.dashboard.product.AssetList },
//                 ],
//               },
//               {
//                 title: 'Report',
//                 path: paths.dashboard.user.root,
//                 children: [
//                   { title: 'report 1', path: paths.dashboard.invoice.root },
//                   { title: 'report 2', path: paths.dashboard.invoice.demo.edit },
//                 ],
//               },
//             ],
//           },
//         ],
//       },
//       {
//         subheader: '',
//         items: [
//           {
//             title: 'Masters',
//             path: paths.dashboard.user.root,
//             icon: ICONS.blog,
//             children: [
//               {
//                 title: 'Global Masters',
//                 path: paths.dashboard.user.root,
//                 children: [
//                   { title: 'Address Purpose', path: paths.dashboard.master.AddressPurpose },
//                   { title: 'Bank Account Type', path: paths.dashboard.master.BankAccountType },
//                   { title: 'Bank Name', path: paths.dashboard.master.BankName },
//                   { title: 'Blood Group', path: paths.dashboard.master.BloodGroup },
//                   { title: 'Business Unit', path: paths.dashboard.master.BusinessUnit },
//                   { title: 'Certificate Types', path: paths.dashboard.master.CertificateType },
//                   { title: 'Certificates', path: paths.dashboard.master.Certificates },
//                   { title: 'Contract Type', path: paths.dashboard.master.ContractType },
//                   { title: 'Delivery Unit', path: paths.dashboard.master.DeliveryUnit },
//                   { title: 'Department', path: paths.dashboard.master.Department },
//                   { title: 'Designation', path: paths.dashboard.master.Designation },
//                   { title: 'Domain', path: paths.dashboard.master.Domain },
//                   { title: 'Employement Type', path: paths.dashboard.master.EmployementType },
//                   { title: 'Gender', path: paths.dashboard.master.Gender },
//                   { title: 'Good', path: paths.dashboard.master.Good },
//                   { title: 'HsnSac', path: paths.dashboard.master.HsnSac },
//                   { title: 'Institute', path: paths.dashboard.master.Institute },
//                   { title: 'Interview Level', path: paths.dashboard.master.InterviewLevel },
//                   { title: 'Interview Status', path: paths.dashboard.master.InterviewStatus },
//                   { title: 'Item Category', path: paths.dashboard.master.ItemCategoryMst },
//                   { title: 'Item Condition', path: paths.dashboard.master.ItemCondition },
//                   { title: 'Item Type', path: paths.dashboard.master.ItemType },
//                   { title: 'Language', path: paths.dashboard.master.Language },
//                   { title: 'Marital Status', path: paths.dashboard.master.MaritalStatus },
//                   { title: 'Meeting Platform', path: paths.dashboard.master.MeetingPlatform },
//                   { title: 'Name Title', path: paths.dashboard.master.NameTitle },
//                   { title: 'Organization Type', path: paths.dashboard.master.OrganizationType },
//                   { title: 'Position Level', path: paths.dashboard.master.PositionLevel },
//                   { title: 'Practice', path: paths.dashboard.master.Practice },
//                   { title: 'Project Status', path: paths.dashboard.master.ProjectStatus },
//                   { title: 'Project Type', path: paths.dashboard.master.PTList },
//                   { title: 'Qualification', path: paths.dashboard.master.Qualification },
//                   { title: 'Qualification Streams', path: paths.dashboard.master.QualificationStreams},
//                   { title: 'Qualification Type', path: paths.dashboard.master.QualificationType },
//                   { title: 'Questions', path: paths.dashboard.master.Questions },
//                   { title: 'Reason', path: paths.dashboard.master.Reason },
//                   { title: 'Relationship', path: paths.dashboard.master.Relationship },
//                   { title: 'Religion', path: paths.dashboard.master.Religion },
//                   { title: 'Requisition Status', path: paths.dashboard.master.ReqStatus },
//                   { title: 'Revenue Type', path: paths.dashboard.master.RevenueType },
//                   { title: 'Roles', path: paths.dashboard.master.Role },
//                   { title: 'Service Category', path: paths.dashboard.master.ServiceCategory },
//                   { title: 'Skills', path: paths.dashboard.master.SkillList },
//                   { title: 'Skill Category', path: paths.dashboard.master.SkillCategory },
//                   { title: 'Source Of Hire', path: paths.dashboard.master.SourceOfHire },
//                   { title: 'TDS Section', path: paths.dashboard.master.TDSSection },
//                   { title: 'Transaction Type', path: paths.dashboard.master.TransactionType },
//                   { title: 'University', path: paths.dashboard.master.University },
//                   { title: 'Unit Of Measure', path: paths.dashboard.master.UOM },
//                   { title: 'Visa Name', path: paths.dashboard.master.VisaName },
//                   { title: 'Visa Type', path: paths.dashboard.master.VisaType },
//                   { title: 'Work Mode', path: paths.dashboard.master.WorkMode },
//                 ],
//               },
//               {
//                 title: 'Local Masters',
//                 path: paths.dashboard.user.root,
//                 children: [
//                   { title: 'Offer Decline Reasons', path: paths.dashboard.master.DeclineReason},
//                   { title: 'Employee Grade', path: paths.dashboard.master.EmpGrade },

//                   { title: 'Interview Cancel Reason', path: paths.dashboard.master.InterviewCancelReason},
//                   { title: 'Modules', path: paths.dashboard.master.Modules },
//                   { title: 'Negative Feedback', path: paths.dashboard.master.NegativeFeedback },
//                   { title: 'Recommendation', path: paths.dashboard.master.Recommendation },
//                   { title: 'Reports', path: paths.dashboard.master.Reports },
//                   { title: 'Role Level', path: paths.dashboard.master.RoleLevel },
//                   { title: 'Screens', path: paths.dashboard.master.Screens },
//                   { title: 'Service', path: paths.dashboard.master.Service },
//                   { title: 'Service Plan', path: paths.dashboard.master.ServicePlan },
//                   { title: 'Sub Inventory', path: paths.dashboard.master.SubInventory },
//                 ],
//               },
//             ],
//           },
//         ],
//       },
//       {
//         subheader: '',
//         items: [
//           {
//             title: 'Project',
//             path: paths.dashboard.user.root,
//             icon: ICONS.project,
//             children: [{ title: 'Project Allocation', path: paths.dashboard.invoice.root }],
//           },
//         ],
//       },
//       {
//         subheader: '',
//         items: [{ title: 'Client', path: paths.dashboard.client.ListClient, icon: ICONS.person }],
//       },
//       {
//         subheader: '',
//         items: [{ title: 'Vendor', path: paths.dashboard.vendor.ListVendor, icon: ICONS.person }],
//       },
//     ],
//     []
//   );

//   return data;
// }

// *************************************************************************************************************************************************************************************************************************************************************************

// import { useMemo } from 'react';
// // routes
// import { paths } from 'src/routes/paths';
// // locales
// import { useLocales } from 'src/locales';
// // components
// import Label from 'src/components/label';
// import Iconify from 'src/components/iconify';
// import SvgColor from 'src/components/svg-color';

// // ----------------------------------------------------------------------

// const icon = (name) => (
//   <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
//   // OR
//   // <Iconify icon="fluent:mail-24-filled" />
//   // https://icon-sets.iconify.design/solar/
//   // https://www.streamlinehq.com/icons
// );

// const ICONS = {
//   job: icon('ic_job'),
//   blog: icon('ic_blog'),
//   chat: icon('ic_chat'),
//   mail: icon('ic_mail'),
//   user: icon('ic_user'),
//   file: icon('ic_file'),
//   lock: icon('ic_lock'),
//   tour: icon('ic_tour'),
//   order: icon('ic_order'),
//   label: icon('ic_label'),
//   blank: icon('ic_blank'),
//   kanban: icon('ic_kanban'),
//   folder: icon('ic_folder'),
//   banking: icon('ic_banking'),
//   booking: icon('ic_booking'),
//   invoice: icon('ic_invoice'),
//   product: icon('ic_product'),
//   calendar: icon('ic_calendar'),
//   disabled: icon('ic_disabled'),
//   external: icon('ic_external'),
//   menuItem: icon('ic_menu_item'),
//   ecommerce: icon('ic_ecommerce'),
//   analytics: icon('ic_analytics'),
//   dashboard: icon('ic_dashboard'),
// };

// // ----------------------------------------------------------------------

// export function useNavData() {
//   const { t } = useLocales();

//   const data = useMemo(
//     () => [
//       // OVERVIEW
//       // ----------------------------------------------------------------------
//       {
//         subheader: t('overview'),
//         items: [
//           {
//             title: t('app'),
//             path: paths.dashboard.root,
//             icon: ICONS.dashboard,
//           },
//           {
//             title: t('ecommerce'),
//             path: paths.dashboard.general.ecommerce,
//             icon: ICONS.ecommerce,
//           },
//           {
//             title: t('analytics'),
//             path: paths.dashboard.general.analytics,
//             icon: ICONS.analytics,
//           },
//           {
//             title: t('banking'),
//             path: paths.dashboard.general.banking,
//             icon: ICONS.banking,
//           },
//           {
//             title: t('booking'),
//             path: paths.dashboard.general.booking,
//             icon: ICONS.booking,
//           },
//           {
//             title: t('file'),
//             path: paths.dashboard.general.file,
//             icon: ICONS.file,
//           },
//         ],
//       },
//       {
//         items: [
//           {
//             title: t('recruitment'),
//             path: paths.dashboard.recruitment.root,
//             icon: ICONS.user,
//             children: [
//               { title: t('tag list'), path: paths.dashboard.recruitment.taglist },
//               { title: t('manager list'), path: paths.dashboard.recruitment.managerlist},
//               { title: t('offer letter'),path: paths.dashboard.recruitment.offerletter},
//               { title: t('offered candidates'), path: paths.dashboard.recruitment.offeredcandidate},
//               {
//                 title: t('Master'),
//                 path: paths.dashboard.recruitment.root,
//                 children: [
//                   {
//                     title: t('Candidate'),
//                     path: paths.dashboard.recruitment.new,
//                   },
//                   {
//                     title: t('List'),
//                     path: paths.dashboard.recruitment.list,
//                   },

//                 ],
//               },
//               {
//                 title: t('interview'),
//                 path: paths.dashboard.tag.root,
//                 children: [
//                   {
//                     title: t('Result And Feedback'),
//                     path: paths.dashboard.tag.view,
//                   },
//                 ],
//               },
//             ],
//           },
//           {
//             title: t('Inventory'),
//             path: paths.dashboard.user.root,
//             icon: ICONS.order,
//           },
//         ],
//       },
//       // CANDIDATE REQUIREMENT
//       {
//         items: [
//           {
//             title: t('Candidate Requirement'),
//             path: paths.dashboard.CandidateRequirement.RequirementList,
//             icon: ICONS.user,
//             children: [
//               { title: t('New'), path: paths.dashboard.CandidateRequirement.NewRequirement },
//               { title: t('List'), path: paths.dashboard.CandidateRequirement.RequirementList },
//             ]
//           }
//         ]
//       },
//       // JD
//       {
//         items: [
//           {
//             title: t('JD'),
//             path: paths.dashboard.jd.newjd,
//             icon: ICONS.user,
//             children: [
//               { title: t('New JD'), path: paths.dashboard.jd.newjd, },
//               { title: t('Past JD'), path: paths.dashboard.jd.pastjd, },
//             ]
//           }
//         ]
//       },

//       {
//         items: [
//           {
//             title: t('interview'),
//             path: paths.dashboard.interview.list,
//             icon: ICONS.user,
//             children: [
//               {
//                 title: t('list'),
//                 path: paths.dashboard.interview.list,
//               },
//               {
//                 title: t('result & feedback'),
//                 path: paths.dashboard.interview.root,
//               },
//             ],
//           },
//         ],
//       },
//       {
//         items: [
//           {
//             title: t('employee'),
//             path: paths.dashboard.employee.root,
//             icon: ICONS.user,
//             children: [
//               {
//                 title: t('list'),
//                 path: paths.dashboard.employee.list,
//               },
//               {
//                 title: t('create'),
//                 path: paths.dashboard.employee.new,
//               },
//             ],
//           },
//         ],
//       },

//       // MANAGEMENT
//       // ----------------------------------------------------------------------
//       {
//         subheader: t('management'),
//         items: [
//           // MASTER
//           {
//             title: t('master'),
//             path: paths.dashboard.master.root,
//             icon: ICONS.user,
//             children: [

//               { title: t('user list'), path: paths.dashboard.master.usermst },
//               { title: t('Address Purpose'), path: paths.dashboard.master.AddressPurpose },
//               { title: t('Bank Account Types'), path: paths.dashboard.master.BankAccountType },
//               { title: t('Bank Names'), path: paths.dashboard.master.BankName },
//               { title: t('Blood Groups'), path: paths.dashboard.master.BloodGroup },
//               { title: t('Business Units'), path: paths.dashboard.master.BusinessUnit },
//               { title: t('Certificates'), path: paths.dashboard.master.Certificates },
//               { title: t('Contract Types'), path: paths.dashboard.master.ContractType },
//               { title: t('Delivery Units'), path: paths.dashboard.master.DeliveryUnit },
//               { title: t('Departments'), path: paths.dashboard.master.Department },
//               { title: t('Designations'), path: paths.dashboard.master.Designation },
//               { title: t('Domain'), path: paths.dashboard.master.Domain },
//               { title: t('Employee Grade'), path: paths.dashboard.master.EmpGrade },
//               { title: t('Employement Types'), path: paths.dashboard.master.EmployementType },
//               { title: t('Gender'), path: paths.dashboard.master.Gender },
//               { title: t('HSN/SAC'), path: paths.dashboard.master.HsnSac },
//               { title: t('Institutes'), path: paths.dashboard.master.Institute },
//               { title: t('Interview Levels'), path: paths.dashboard.master.InterviewLevel },
//               { title: t('Interview Status'), path: paths.dashboard.master.InterviewStatus },
//               { title: t('Item Category'), path: paths.dashboard.master.ItemCategoryMst },
//               { title: t('Item Condition'), path: paths.dashboard.master.ItemCondition },
//               { title: t('Item Types'), path: paths.dashboard.master.ItemType },
//               { title: t('Languages'), path: paths.dashboard.master.Language },
//               // { title: t('Marital Status'), path: paths.dashboard.master.maritalStatus },
//               { title: t('Meeting Platform'), path: paths.dashboard.master.MeetingPlatform },
//               { title: t('Modules'), path: paths.dashboard.master.Modules },
//               { title: t('Negative Feedback'), path: paths.dashboard.master.NegativeFeedback },
//               { title: t('Organization Types'), path: paths.dashboard.master.OrganizationType },
//               { title: t('Position Level'), path: paths.dashboard.master.PositionLevel },
//               { title: t('Practices'), path: paths.dashboard.master.Practice },
//               { title: t('Project Status '), path: paths.dashboard.master.ProjectStatus },
//               { title: t('Project Types'), path: paths.dashboard.master.PTList },
//               { title: t('Qualification Streams'), path: paths.dashboard.master.QualificationStreams },
//               { title: t('Qualification Types'), path: paths.dashboard.master.QualificationType },
//               { title: t('Qualification'), path: paths.dashboard.master.Qualification },
//               { title: t('Questions'), path: paths.dashboard.master.Questions },
//               { title: t('Reasons'), path: paths.dashboard.master.Reason },
//               { title: t('Recommendations'), path: paths.dashboard.master.Recommendation },
//               { title: t('Relationship'), path: paths.dashboard.master.Relationship },
//               { title: t('Religions'), path: paths.dashboard.master.Religion },
//               { title: t('Reports'), path: paths.dashboard.master.Reports },
//               { title: t('Requisition Status'), path: paths.dashboard.master.ReqStatus },
//               { title: t('Revenue Types'), path: paths.dashboard.master.RevenueType },
//               { title: t('Roles'), path: paths.dashboard.master.Role },
//               { title: t('Salutation'), path: paths.dashboard.master.NameTitle },
//               { title: t('Screens'), path: paths.dashboard.master.Screens },
//               { title: t('Service Category'), path: paths.dashboard.master.ServiceCategory },
//               { title: t('Service Plans'), path: paths.dashboard.master.ServicePlan },
//               { title: t('Skills'), path: paths.dashboard.master.list },
//               { title: t('Skill Category'), path: paths.dashboard.master.SkillCategory },
//               { title: t('Source Of Hire'), path: paths.dashboard.master.SourceOfHire },
//               { title: t('Sub Inventory'), path: paths.dashboard.master.SubInventory },
//               { title: t('TDS Section'), path: paths.dashboard.master.TDSSection },
//               { title: t('Transaction Types'), path: paths.dashboard.master.TransactionType },
//               { title: t('Unit Of Measure'), path: paths.dashboard.master.UOM },
//               { title: t('University'), path: paths.dashboard.master.University },
//               { title: t('Visa Names'), path: paths.dashboard.master.VisaName },
//               { title: t('Visa Types'), path: paths.dashboard.master.VisaType },
//               { title: t('Work Modes '), path: paths.dashboard.master.WorkMode },

//             ],
//           },
//           // Client
//           {
//             title: t('client'),
//             path: paths.dashboard.client.root,
//             icon: ICONS.user,
//             children: [
//               { title: t('list'), path: paths.dashboard.client.list },
//               { title: t('New'), path: paths.dashboard.client.clientMst },

//             ],
//           },
//           // VENDOR
//           {
//             title: t('vendor'),
//             path: paths.dashboard.vendor.root,
//             icon: ICONS.user,
//             children: [
//               { title: t('list'), path: paths.dashboard.vendor.list },

//             ],
//           },
//           // USER
//           {
//             title: t('user'),
//             path: paths.dashboard.user.root,
//             icon: ICONS.user,
//             children: [
//               { title: t('profile'), path: paths.dashboard.user.root },
//               { title: t('cards'), path: paths.dashboard.user.cards },
//               { title: t('list'), path: paths.dashboard.user.list },
//               { title: t('create'), path: paths.dashboard.user.new },
//               { title: t('edit'), path: paths.dashboard.user.demo.edit },
//               { title: t('account'), path: paths.dashboard.user.account },
//             ],
//           },

//           // PRODUCT
//           {
//             title: t('product'),
//             path: paths.dashboard.product.root,
//             icon: ICONS.product,
//             children: [
//               { title: t('Item Master'), path: paths.dashboard.product.root },
//               // {
//               //   title: t('details'),
//               //   path: paths.dashboard.product.demo.details,
//               // },
//               // { title: t('create'), path: paths.dashboard.product.new },
//               // { title: t('edit'), path: paths.dashboard.product.demo.edit },
//               // { title: t('requisition'), path: paths.dashboard.product.req },
//               { title: t('Item Requisition'), path: paths.dashboard.product.ItemCategoryMst },
//               { title: t('Inventory Transaction'), path: paths.dashboard.product.InvTransaction },
//               { title: t('Assets'), path: paths.dashboard.product.AssetList },
//               { title: t('Asset Tracker'), path: paths.dashboard.product.AssetTrackerList },
//               { title: t('Inventory On Hand'), path: paths.dashboard.product.InvOnHandList },
//               {
//                 title: t('Physical Inventory'),
//                 path: paths.dashboard.product.AllPhysicalInventoryList,
//               },
//               {
//                 title: t('Asset Tracker Admin'),
//                 path: paths.dashboard.product.AssetTrackerAdminList,
//               },
//               {
//                 title: t('Inspection Parameter Master'),
//                 path: paths.dashboard.product.InspectionParameterMasterList,
//               },
//               {
//                 title: t('Asset Inspection'),
//                 path: paths.dashboard.product.InspectionList,
//               },
//             ],
//           },

//           // ORDER
//           {
//             title: t('order'),
//             path: paths.dashboard.order.root,
//             icon: ICONS.order,
//             children: [
//               { title: t('list'), path: paths.dashboard.order.root },
//               { title: t('details'), path: paths.dashboard.order.demo.details },
//             ],
//           },

//           // INVOICE
//           {
//             title: t('invoice'),
//             path: paths.dashboard.invoice.root,
//             icon: ICONS.invoice,
//             children: [
//               { title: t('list'), path: paths.dashboard.invoice.root },
//               {
//                 title: t('details'),
//                 path: paths.dashboard.invoice.demo.details,
//               },
//               { title: t('create'), path: paths.dashboard.invoice.new },
//               { title: t('edit'), path: paths.dashboard.invoice.demo.edit },
//             ],
//           },

//           // BLOG
//           {
//             title: t('blog'),
//             path: paths.dashboard.post.root,
//             icon: ICONS.blog,
//             children: [
//               { title: t('list'), path: paths.dashboard.post.root },
//               { title: t('details'), path: paths.dashboard.post.demo.details },
//               { title: t('create'), path: paths.dashboard.post.new },
//               { title: t('edit'), path: paths.dashboard.post.demo.edit },
//             ],
//           },

//           // JOB
//           {
//             title: t('job'),
//             path: paths.dashboard.job.root,
//             icon: ICONS.job,
//             children: [
//               { title: t('list'), path: paths.dashboard.job.root },
//               { title: t('details'), path: paths.dashboard.job.demo.details },
//               { title: t('create'), path: paths.dashboard.job.new },
//               { title: t('edit'), path: paths.dashboard.job.demo.edit },
//             ],
//           },

//           // TOUR
//           {
//             title: t('tour'),
//             path: paths.dashboard.tour.root,
//             icon: ICONS.tour,
//             children: [
//               { title: t('list'), path: paths.dashboard.tour.root },
//               { title: t('details'), path: paths.dashboard.tour.demo.details },
//               { title: t('create'), path: paths.dashboard.tour.new },
//               { title: t('edit'), path: paths.dashboard.tour.demo.edit },
//             ],
//           },

//           // FILE MANAGER
//           {
//             title: t('file_manager'),
//             path: paths.dashboard.fileManager,
//             icon: ICONS.folder,
//           },

//           // MAIL
//           {
//             title: t('mail'),
//             path: paths.dashboard.mail,
//             icon: ICONS.mail,
//             info: <Label color="error">+32</Label>,
//           },

//           // CHAT
//           {
//             title: t('chat'),
//             path: paths.dashboard.chat,
//             icon: ICONS.chat,
//           },

//           // CALENDAR
//           {
//             title: t('calendar'),
//             path: paths.dashboard.calendar,
//             icon: ICONS.calendar,
//           },

//           // KANBAN
//           {
//             title: t('kanban'),
//             path: paths.dashboard.kanban,
//             icon: ICONS.kanban,
//           },
//         ],
//       },

//       // DEMO MENU STATES
//       {
//         subheader: t(t('other_cases')),
//         items: [
//           {
//             title: t('item_by_roles'),
//             path: paths.dashboard.permission,
//             icon: ICONS.lock,
//             roles: ['admin', 'manager'],
//             caption: t('only_admin_can_see_this_item'),
//           },
//           {
//             title: t('menu_level'),
//             path: '#/dashboard/menu_level',
//             icon: ICONS.menuItem,
//             children: [
//               {
//                 title: t('menu_level_1a'),
//                 path: '#/dashboard/menu_level/menu_level_1a',
//               },
//               {
//                 title: t('menu_level_1b'),
//                 path: '#/dashboard/menu_level/menu_level_1b',
//                 children: [
//                   {
//                     title: t('menu_level_2a'),
//                     path: '#/dashboard/menu_level/menu_level_1b/menu_level_2a',
//                   },
//                   {
//                     title: t('menu_level_2b'),
//                     path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b',
//                     children: [
//                       {
//                         title: t('menu_level_3a'),
//                         path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3a',
//                       },
//                       {
//                         title: t('menu_level_3b'),
//                         path: '#/dashboard/menu_level/menu_level_1b/menu_level_2b/menu_level_3b',
//                       },
//                     ],
//                   },
//                 ],
//               },
//             ],
//           },
//           {
//             title: t('item_disabled'),
//             path: '#disabled',
//             icon: ICONS.disabled,
//             disabled: true,
//           },
//           {
//             title: t('item_label'),
//             path: '#label',
//             icon: ICONS.label,
//             info: (
//               <Label color="info" startIcon={<Iconify icon="solar:bell-bing-bold-duotone" />}>
//                 NEW
//               </Label>
//             ),
//           },
//           {
//             title: t('item_caption'),
//             path: '#caption',
//             icon: ICONS.menuItem,
//             caption:
//               'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
//           },
//           {
//             title: t('item_external_link'),
//             path: 'https://www.google.com/',
//             icon: ICONS.external,
//           },
//           {
//             title: t('blank'),
//             path: paths.dashboard.blank,
//             icon: ICONS.blank,
//           },
//         ],
//       },
//     ],
//     [t]
//   );

//   return data;
// }
