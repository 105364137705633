import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { element } from 'prop-types';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// Tag Dashboard
const OverviewTagPage = lazy(() => import('src/pages/dashboard/tag'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
const ItemReqPage = lazy(() => import('src/pages/dashboard/product/requisition'));
const ItemCategoryPage2 = lazy(() => import('src/pages/dashboard/product/ItemCategoryMst'));
const InvTransactionPage = lazy(() => import('src/pages/dashboard/product/InvTransaction'));
const InvTransactionListPage = lazy(() => import('src/pages/dashboard/product/InvTransactionList'));
const AssetsListPage = lazy(() => import('src/pages/dashboard/product/AssetList'));
const AssetsTrackerListPage = lazy(() => import('src/pages/dashboard/product/AssetTrackerList'));
const PhysicalInventoryListPage = lazy(() =>
  import('src/pages/dashboard/product/PhysicalInventoryList')
);
const InvOnHandListPage = lazy(() => import('src/pages/dashboard/product/InvOnHandList'));
const AllPhysicalInvListPage = lazy(() => import('src/pages/dashboard/product/AllPhysicalInvList'));
const AssetsTrackerAdminListPage = lazy(() =>
  import('src/pages/dashboard/product/AssetTrackerAdminList')
);
const InspectionParameterMasterListPage = lazy(() =>
  import('src/pages/dashboard/product/InspectionParameterMasterList')
);
const InspectionPage = lazy(() => import('src/pages/dashboard/product/InspectionList'));
const InvComparisonPage = lazy(() => import('src/pages/dashboard/product/InvComparisonList'));
const PurchaseRequisitionsPage = lazy(() =>
  import('src/pages/dashboard/product/PurchaseRequisitionList')
);
const ReservedAssetsPage = lazy(() => import('src/pages/dashboard/product/ReservedAssetsList'));
const AllItemRequisitionsTagListPage = lazy(() =>
  import('src/pages/dashboard/product/AllItemRequisitionsTagList')
);
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const ListAndCalendarViewPage = lazy(() => import('src/pages/dashboard/listAndCalendarView'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const CalendarListViewPage = lazy(() => import('src/pages/dashboard/calendarListView'));
const CalendarFormPage = lazy(() => import('src/pages/dashboard/calendarform'));
const CalenderUpdate = lazy(() => import('src/pages/dashboard/calender-update'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// Master Page
const UserListView = lazy(() => import('src/pages/dashboard/master/UserMst'));
const UserForm = lazy(() => import('src/pages/dashboard/master/UserForm'));
const SkillListPage = lazy(() => import('src/pages/dashboard/master/SkillList'));
const ProjectTypeListPage = lazy(() => import('src/pages/dashboard/master/PTList'));
const ItemCategoryPage = lazy(() => import('src/pages/dashboard/master/ItemCategoryMst'));
const PracticePage = lazy(() => import('src/pages/dashboard/master/Practice'));
const ContractTypePage = lazy(() => import('src/pages/dashboard/master/ContractType'));
const CertificateTypePage = lazy(() => import('src/pages/dashboard/master/CertificateType'));
const DeclineReasonPage = lazy(() => import('src/pages/dashboard/master/DeclineReason'));
const OfferWithrawReasonPage = lazy(() => import('src/pages/dashboard/master/OfferWithdrawReason'));
const DepartmentPage = lazy(() => import('src/pages/dashboard/master/Department'));
const DesignationPage = lazy(() => import('src/pages/dashboard/master/Designation'));
const InterviewLevelPage = lazy(() => import('src/pages/dashboard/master/InterviewLevel'));
const BusinessUnitPage = lazy(() => import('src/pages/dashboard/master/BusinessUnit'));
const DeliveryUnitPage = lazy(() => import('src/pages/dashboard/master/DeliveryUnit'));
const VisaTypePage = lazy(() => import('src/pages/dashboard/master/VisaType'));
const VisaNamePage = lazy(() => import('src/pages/dashboard/master/VisaName'));
const RelationshipPage = lazy(() => import('src/pages/dashboard/master/Relationship'));
const ReligionPage = lazy(() => import('src/pages/dashboard/master/Religion'));
const RolePage = lazy(() => import('src/pages/dashboard/master/Role'));
const RoleLevelPage = lazy(() => import('src/pages/dashboard/master/RoleLevel'));
const SkillCategoryPage = lazy(() => import('src/pages/dashboard/master/SkillCategory'));
const SourceOfHirePage = lazy(() => import('src/pages/dashboard/master/SourceOfHire'));
const NameTitlePage = lazy(() => import('src/pages/dashboard/master/NameTitle'));
const MaritalStatusPage = lazy(() => import('src/pages/dashboard/master/maritalstatus'));
const MeetingPlatformPage = lazy(() => import('src/pages/dashboard/master/MeetingPlatform'));
const PositionLevelPage = lazy(() => import('src/pages/dashboard/master/PositionLevel'));
const QualificationPage = lazy(() => import('src/pages/dashboard/master/Qualification'));
const EmpGradePage = lazy(() => import('src/pages/dashboard/master/EmpGrade'));
const GenderPage = lazy(() => import('src/pages/dashboard/master/Gender'));
const EmployementTypePage = lazy(() => import('src/pages/dashboard/master/EmployementType'));
const QualificationStreamsPage = lazy(() =>
  import('src/pages/dashboard/master/QualificationStreams')
);
const SubInventoryPage = lazy(() => import('src/pages/dashboard/master/SubInv'));
const UOMPage = lazy(() => import('src/pages/dashboard/master/UOM'));
const UniversityPage = lazy(() => import('src/pages/dashboard/master/University'));
const WorkModePage = lazy(() => import('src/pages/dashboard/master/WorkMode'));
const BloodGroupPage = lazy(() => import('src/pages/dashboard/master/BloodGroup'));
const ItemTypePage = lazy(() => import('src/pages/dashboard/master/ItemType'));
const ItemConditionPage = lazy(() => import('src/pages/dashboard/master/ItemCondition'));
const TransactionTypePage = lazy(() => import('src/pages/dashboard/master/TransactionType'));
const AddressPurposePage = lazy(() => import('src/pages/dashboard/master/AddressPurpose'));
const BankAccountTypePage = lazy(() => import('src/pages/dashboard/master/BankAccountType'));
const BankNamePage = lazy(() => import('src/pages/dashboard/master/BankName'));
const DomainPage = lazy(() => import('src/pages/dashboard/master/Domain'));
const OrganizationTypePage = lazy(() => import('src/pages/dashboard/master/OrganizationType'));
const ProjectStatusPage = lazy(() => import('src/pages/dashboard/master/ProjectStatus'));
const RevenueTypePage = lazy(() => import('src/pages/dashboard/master/RevenueType'));
const ServiceCategoryPage = lazy(() => import('src/pages/dashboard/master/ServiceCategory'));
const ServicePlanPage = lazy(() => import('src/pages/dashboard/master/ServicePlan'));
const TDSPage = lazy(() => import('src/pages/dashboard/master/TDS'));
const ReqStatusPage = lazy(() => import('src/pages/dashboard/master/ReqStatus'));
const HSNSACPage = lazy(() => import('src/pages/dashboard/master/HsnSac'));
const ImpQuePage = lazy(() => import('src/pages/dashboard/master/ImpQue'));
const LanguagePage = lazy(() => import('src/pages/dashboard/master/Language'));
const QualTypePage = lazy(() => import('src/pages/dashboard/master/QualificationType'));
const InterviewStatusPage = lazy(() => import('src/pages/dashboard/master/InterviewStatus'));
const ScreensPage = lazy(() => import('src/pages/dashboard/master/Screens'));
const ModulesPage = lazy(() => import('src/pages/dashboard/master/Modules'));
const ReportsPage = lazy(() => import('src/pages/dashboard/master/Reports'));
const CertificatePage = lazy(() => import('src/pages/dashboard/master/Certificates'));
const ReasonPage = lazy(() => import('src/pages/dashboard/master/Reason'));
const InstitutePage = lazy(() => import('src/pages/dashboard/master/Institute'));
const RecommendationPage = lazy(() => import('src/pages/dashboard/master/Recommendation'));
const NegativeFeedbackPage = lazy(() => import('src/pages/dashboard/master/NegativeFeedback'));
const GoodPage = lazy(() => import('src/pages/dashboard/master/Good'));
const ServicePage = lazy(() => import('src/pages/dashboard/master/Service'));
const InterviewCancelReasonPage = lazy(() =>
  import('src/pages/dashboard/master/InterviewCancelReason')
);
const InspectionParameterPage = lazy(() =>
  import('src/pages/dashboard/master/InspectionParameter')
);

const UniqueIdentityDocPage = lazy(()=> import('src/pages/dashboard/master/UniqueIdentityDoc'))

// Client Page
const ClientListPage = lazy(() => import('src/pages/dashboard/client/ListClient'));
const ClientMstPage = lazy(() => import('src/pages/dashboard/client/ClientMst'));
const ClientEditPage = lazy(() => import('src/pages/dashboard/client/EditClient'));
const ClientDetailPage = lazy(() => import('src/pages/dashboard/client/DetailClient'));

// PROJECT
const ProjectListPage = lazy(() => import('src/pages/dashboard/project/projectlist'));
const ProjectFormPages = lazy(() => import('src/pages/dashboard/project/projectform'));
const ProjectEditPage = lazy(() => import('src/pages/dashboard/project/edit'));
const ProjectDetailPage = lazy(() => import('src/pages/dashboard/project/detail'));
// PROJECT ALLOCATION
const ProjectAllocationPage = lazy(() =>
  import('src/pages/dashboard/project/projectallocation/projectallocationform')
);

// Vendor Page
const VendorListPage = lazy(() => import('src/pages/dashboard/vendor/ListVendor'));
const VendorMstPage = lazy(() => import('src/pages/dashboard/vendor/VendorMst'));
const VendorEditPage = lazy(() => import('src/pages/dashboard/vendor/EditVendor'));
const VendorDetailPage = lazy(() => import('src/pages/dashboard/vendor/DetailVendor'));

// JD
const NewJdPage = lazy(() => import('src/pages/dashboard/JD/newjd'));
const PastJdListPage = lazy(() => import('src/pages/dashboard/JD/pastjdlist'));
const EditJdPage = lazy(() => import('src/pages/dashboard/JD/editjd'));
const ReuseJdPage = lazy(() => import('src/pages/dashboard/JD/reusejd'));
const DetailsJdPage = lazy(() => import('src/pages/dashboard/JD/detailsjd'));

// TENANT CONFIGURATION
const TenantConfigurationPage = lazy(() => import('src/pages/dashboard/TenantConfig/tenantconfig'));

// Manager Approvals
const CanReqApprovalPage = lazy(() => import('src/pages/dashboard/ManagerApproval/CanReqApproval'));
const CancelCanReqPage = lazy(() =>
  import('src/pages/dashboard/ManagerApproval/CancelCanReqApproval')
);
const PendingCancelCanReq = lazy(() =>
  import('src/pages/dashboard/ManagerApproval/PendingCancelCanReq')
);
const ApprovedCancelCanReq = lazy(() =>
  import('src/pages/dashboard/ManagerApproval/ApprovedCancelCanReq')
);
const RejectedCancelCanReq = lazy(() =>
  import('src/pages/dashboard/ManagerApproval/RejectedCancelCanReq')
);
const MaterialReqApprovalPage = lazy(() =>
  import('src/pages/dashboard/ManagerApproval/MaterialReqApproval')
);
const PurchaseReqApprovalPage = lazy(() =>
  import('src/pages/dashboard/ManagerApproval/PurchaseReqApproval')
);
const PendingCanReqApprovalPage = lazy(() =>
  import('src/pages/dashboard/ManagerApproval/PendingCanReqApproval')
);
const ApproveCanReqPage = lazy(() =>
  import('src/pages/dashboard/ManagerApproval/ApproveCanReqPage')
);
const RejectCanReqPage = lazy(() => import('src/pages/dashboard/ManagerApproval/RejectCanReqPage'));
const PendingMatReqApprovalPage = lazy(() =>
  import('src/pages/dashboard/ManagerApproval/PenMatReq')
);
const ApproveMatReqApprovalPage = lazy(() =>
  import('src/pages/dashboard/ManagerApproval/ApproveMatReq')
);
const RejectedMatReqApprovalPage = lazy(() =>
  import('src/pages/dashboard/ManagerApproval/RejMatReq')
);
const PendingPurReqApprovalPage = lazy(() =>
  import('src/pages/dashboard/ManagerApproval/PenPurReqApproval')
);
const ApprovePurReqPage = lazy(() =>
  import('src/pages/dashboard/ManagerApproval/ApprovedPurchaseReqPage')
);
const RejectedPurReqPagen = lazy(() =>
  import('src/pages/dashboard/ManagerApproval/RejectedPurReqPage')
);

// CANDIDATE REQUIREMENT
const CanReqCreatePage = lazy(() => import('src/pages/dashboard/CanReq/newreq'));
const CanReqEditPage = lazy(() => import('src/pages/dashboard/CanReq/editreq'));
const CanReqReusePage = lazy(() => import('src/pages/dashboard/CanReq/reusereq'));
const ReqListPage = lazy(() => import('src/pages/dashboard/CanReq/reqlist'));
const CanReqDetailsPage = lazy(() => import('src/pages/dashboard/CanReq/details'));

//  RECRUITMENT
const TagListPage = lazy(() => import('src/pages/dashboard/recruitment/taglist'));
const CandidateViewListPages = lazy(() =>
  import('src/pages/dashboard/recruitment/candidateviewlist')
);
const ManagerListPages = lazy(() => import('src/pages/dashboard/recruitment/managerlist'));
const ManagerCandidateViewListPages = lazy(() =>
  import('src/pages/dashboard/recruitment/managercandidateviewlist')
);
const OfferLetterPages = lazy(() => import('src/pages/dashboard/recruitment/offerletter'));
const OfferedCandidatePages = lazy(() =>
  import('src/pages/dashboard/recruitment/offeredcandidate')
);
const SelectedCandidatePages = lazy(() =>
  import('src/pages/dashboard/recruitment/selectedcandidate')
);
const OfferRejectedPages = lazy(() => import('src/pages/dashboard/recruitment/offerrejected'));
const OfferFetchPage = lazy(() => import('src/pages/dashboard/recruitment/offerfetch'));
const ReofferPage = lazy(() => import('src/pages/dashboard/recruitment/reoffer'));
const RequirementFetchPage = lazy(() => import('src/pages/dashboard/recruitment/requirementfetch'));
const OfferEditPage = lazy(() => import('src/pages/dashboard/recruitment/offeredit'));
const InterviewFetchPage = lazy(() => import('src/pages/dashboard/recruitment/interviewfetch'));

// Interview
const ResultFeedbackTagPage = lazy(() =>
  import('src/pages/dashboard/recruitment/interview/result')
);

// Employee
const EmployeeListPage = lazy(() => import('src/pages/dashboard/employee/list'));
const EmployeeCreatePage = lazy(() => import('src/pages/dashboard/employee/new'));
const EmployeeEditPage = lazy(() => import('src/pages/dashboard/employee/edit'));
const EmployeeDetailsPage = lazy(() => import('src/pages/dashboard/employee/details'));

// Candidate

const CandidateCreatePage = lazy(() => import('src/pages/dashboard/recruitment/candidate/new'));
const CandidateListPage = lazy(() => import('src/pages/dashboard/recruitment/candidate/list'));
const CandidateEditPage = lazy(() => import('src/pages/dashboard/recruitment/candidate/edit'));
const CandidateDetailPage = lazy(() => import('src/pages/dashboard/recruitment/candidate/detail'));

// Interviewer
// Interview
const AssignedInterviewListPage = lazy(() =>
  import('src/pages/dashboard/interviewer/interview/list')
);
const ResultFeedbackPage = lazy(() => import('src/pages/dashboard/interviewer/interview/result'));
const ResultFeedbackEditPage = lazy(() => import('src/pages/dashboard/interviewer/interview/edit'));
const ResultFeedbackDetailPage = lazy(() =>
  import('src/pages/dashboard/interviewer/interview/details')
);

// RBAC
const UserRoleAssignPage = lazy(() => import('src/pages/dashboard/product/UserRoleAssignList'));
// Super-Admin

// Role-Permission

const RolePermissionCreatePage = lazy(() =>
  import('src/pages/dashboard/super-admin/role-permission/new')
);
const RolePermissionEditPage = lazy(() =>
  import('src/pages/dashboard/super-admin/role-permission/edit')
);
const RolePermissionListPage = lazy(() =>
  import('src/pages/dashboard/super-admin/role-permission/list')
);
const RolePermissionDetailPage = lazy(() =>
  import('src/pages/dashboard/super-admin/role-permission/detail')
);


// ToDo

const ToDoPage = lazy(() => import('src/pages/dashboard/ToDo/TagToDo'));



// My Requisitions by Role
// Candidate Requisitions
const CanReqEditPag = lazy(() => import('src/pages/dashboard/MyRequisitions/editreq'));
const CanReqReusePag = lazy(() => import('src/pages/dashboard/MyRequisitions/reusereq'));


const ReqListPag = lazy(() => import('src/pages/dashboard/MyRequisitions/reqlist'));
const CanReqCreateP = lazy(() => import('src/pages/dashboard/MyRequisitions/newreq'));

const ManagerListP = lazy(() => import('src/pages/dashboard/MyRequisitions/managerlist'));
const ManagerCandidateViewListP = lazy(() =>
  import('src/pages/dashboard/MyRequisitions/managercandidateviewlist')
);

// Material requisition
const DetailsPage = lazy(() => import('src/pages/dashboard/MyRequisitions/details'));
const ListPage = lazy(() => import('src/pages/dashboard/MyRequisitions/list'));
const CreatePage = lazy(() => import('src/pages/dashboard/MyRequisitions/new'));
const EditPage = lazy(() => import('src/pages/dashboard/MyRequisitions/edit'));
const ItemReq = lazy(() => import('src/pages/dashboard/MyRequisitions/requisition'));
const ItemCategory = lazy(() => import('src/pages/dashboard/MyRequisitions/ItemCategoryMst'));

// Purchase Requisitions
const PRPage = lazy(() => import('src/pages/dashboard/MyRequisitions/PR'));

const PurchaseRequisitionsP = lazy(() =>
  import('src/pages/dashboard/MyRequisitions/PurchaseRequisitionList')
);
// const PhysicalInventoryList = lazy(() =>
//   import('src/pages/dashboard/MyRequisitions/PhysicalInventoryList')
// );

const AllItemRequisitionsTagListP = lazy(() =>
  import('src/pages/dashboard/MyRequisitions/AllItemRequisitionsTagList')
);

// Add this import
const UploadDocPage = lazy(() => import('src/pages/dashboard/employee/uploadDoc'));
const UploadDocView = lazy(() => import('src/pages/dashboard/employee/uploadDocView'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'booking', element: <OverviewBookingPage /> },
      { path: 'file', element: <OverviewFilePage /> },
      // Tag Dashboard
      { path: 'tag', element: <OverviewTagPage /> },
      {
        path: 'admin',
        children: [
          { element: <TenantConfigurationPage />, index: true },
          { element: <UserListView />, index: true },
        ],
      },
      {
        path: 'preferences',
        children: [
          { element: <TenantConfigurationPage />, index: true },
          { path: 'tenantconfig', element: <TenantConfigurationPage /> },
        ],
      },
      {
        path: 'humanResources',
        children: [
          { element: <EmployeeListPage />, index: true },
          { path: 'ListEmployee', element: <EmployeeListPage /> },
          { element: <EmployeeCreatePage />, index: true },
          { path: 'NewEmployee', element: <EmployeeCreatePage /> },
          { element: <EmployeeEditPage />, index: true },
          { path: 'EditEmployee/:id', element: <EmployeeEditPage /> },
          { path: 'ViewEmployee/:id', element: <EmployeeDetailsPage /> },
          { path: 'EmployeeDocumentUpload/:id', element: <UploadDocPage /> },
          { path: 'EmployeeDocumentUploadView/:id', element: <UploadDocView /> },
        ],
      },
      {
        path: 'talentAcquisition',
        children: [
          { element: <OverviewTagPage />, index: true },
          { path: 'NewRequirement', element: <CanReqCreatePage /> },
          { element: <CanReqEditPage />, index: true },
          { path: 'EditReq/:id', element: <CanReqEditPage /> },
          { element: <CanReqReusePage />, index: true },
          { path: 'ReuseReq/:id', element: <CanReqReusePage /> },
          { element: <CanReqDetailsPage />, index: true },
          { path: 'DetailsReq/:id', element: <CanReqDetailsPage /> },
          { element: <ReqListPage />, index: true },
          { path: 'RequirementList', element: <ReqListPage /> },
          { element: <TagListPage />, index: true },
          { path: 'CanReqTagList', element: <TagListPage /> },
          { element: <CandidateViewListPages />, index: true },
          { path: 'CandidateStatusViewList/:id', element: <CandidateViewListPages /> },
          { element: <ManagerListPages />, index: true },
          { path: 'CanReqManagerList', element: <ManagerListPages /> },
          { element: <ManagerCandidateViewListPages />, index: true },
          { path: 'CandidateStatusViewMgrList/:id', element: <ManagerCandidateViewListPages /> },
          { element: <OfferLetterPages />, index: true },
          { path: 'OfferLetter', element: <OfferLetterPages /> },
          { element: <OfferedCandidatePages />, index: true },
          { path: 'OfferedCandidates', element: <OfferedCandidatePages /> },
          { element: <SelectedCandidatePages />, index: true },
          { path: 'SelectedCandidates', element: <SelectedCandidatePages /> },
          { element: <OfferFetchPage />, index: true },
          { path: ':id/offerfetch', element: <OfferFetchPage /> },
          { element: <ReofferPage />, index: true },
          { path: ':id/reoffer', element: <ReofferPage /> },
          { path: ':id/requirementfetch', element: <RequirementFetchPage /> },
          { element: <OfferEditPage />, index: true },
          { path: ':id/offeredit', element: <OfferEditPage /> },
          { path: ':id/interviewfetch', element: <InterviewFetchPage /> },
          { path: 'CanReqManagerList', element: <ManagerListP /> },
          { path: 'CandidateStatusViewMgrList/:id', element: <ManagerCandidateViewListPages /> },
          { path: 'listAndCalendarView', element: <ListAndCalendarViewPage /> },
          { path: 'calendar', element: <CalendarPage /> },
          { path: 'calendarListView', element: <CalendarListViewPage /> },
          { path: 'calendarform', element: <CalendarFormPage /> },
          { path: 'calenderUpdate/:arg', element: <CalenderUpdate /> },
          { path: 'InterviewFeedbackView/:id', element: <ResultFeedbackTagPage /> },
          { path: 'ListInterview', element: <AssignedInterviewListPage /> },
          { path: 'InterviewResult/:id', element: <ResultFeedbackPage /> },
          { path: 'InterviewResultEdit/:id', element: <ResultFeedbackEditPage /> },
          { path: 'InterviewResultView/:id', element: <ResultFeedbackDetailPage /> },

          {
            path: 'master',
            children: [
              { element: <NewJdPage />, index: true },
              { path: 'NewJd', element: <NewJdPage /> },
              { element: <PastJdListPage />, index: true },
              { path: 'PastJd', element: <PastJdListPage /> },
              { element: <EditJdPage />, index: true },
              { path: 'EditJd/:id', element: <EditJdPage /> },
              { element: <ReuseJdPage />, index: true },
              { path: 'ReuseJd/:id', element: <ReuseJdPage /> },
              { element: <DetailsJdPage />, index: true },
              { path: 'DetailsJd/:id', element: <DetailsJdPage /> },

              { element: <CandidateCreatePage />, index: true },
              { path: 'NewCandidate', element: <CandidateCreatePage /> },
              { element: <CandidateListPage />, index: true },
              { path: 'ListCandidate', element: <CandidateListPage /> },
              { element: <CandidateEditPage />, index: true },
              { path: 'EditCandidate/:id', element: <CandidateEditPage /> },
              { element: <CandidateDetailPage />, index: true },
              { path: 'ViewCandidate/:id', element: <CandidateDetailPage /> },
            ],
          },
        ],
      },
      {
        path: 'assets',
        children: [
          {
            element: <AssetsTrackerListPage />,
            index: true,
          },
          { path: 'AssetTrackerList', element: <AssetsTrackerListPage /> },
          { path: 'AssetTrackerAdminList', element: <AssetsTrackerAdminListPage /> },
          { path: 'reservedassets', element: <ReservedAssetsPage /> },
        ],
      },
      {
        path: 'procurement',
        children: [
          { element: <PurchaseRequisitionsPage />, index: true },
          { path: 'purchasereq', element: <PurchaseRequisitionsPage /> },
        ],
      },

      {
        path: 'inventory',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'ItemRequisition', element: <ItemCategoryPage2 /> },
          { element: <InvTransactionPage />, index: true },
          { path: 'InvTransaction/:id/:type', element: <InvTransactionPage /> },
          { element: <InvTransactionListPage />, index: true },
          { path: 'InvTransactionList', element: <InvTransactionListPage /> },
          { element: <AssetsListPage />, index: true },
          { path: 'AssetList', element: <AssetsListPage /> },
          { element: <AssetsTrackerListPage />, index: true },

          { element: <InvOnHandListPage />, index: true },
          { path: 'InvOnHandList', element: <InvOnHandListPage /> },
          { element: <PhysicalInventoryListPage />, index: true },
          { path: 'PhysicalInventoryList/:id', element: <PhysicalInventoryListPage /> },
          { element: <AllPhysicalInvListPage />, index: true },
          { path: 'AllPhysicalInventoryList', element: <AllPhysicalInvListPage /> },
          { element: <AssetsTrackerAdminListPage />, index: true },

          { element: <InspectionParameterMasterListPage />, index: true },
          { path: 'InspectionParameterMasterList', element: <InspectionParameterMasterListPage /> },
          { element: <InspectionPage />, index: true },
          { path: 'InspectionList', element: <InspectionPage /> },
          { path: ':req', element: <ItemReqPage /> },
          { element: <UserRoleAssignPage />, index: true },

          { element: <AllItemRequisitionsTagListPage />, index: true },
          { path: 'AllItemReqTag', element: <AllItemRequisitionsTagListPage /> },
          { element: <InvComparisonPage />, index: true },
          { path: 'invcomparison/:id', element: <InvComparisonPage /> },

          {
            path: 'master',
            children: [
              { element: <AssetsListPage />, index: true },
              { path: 'AssetList', element: <AssetsListPage /> },
              { element: <ProductListPage />, index: true },
              { path: 'list', element: <ProductListPage /> },
              { path: ':id', element: <ProductDetailsPage /> },
              { element: <ProductCreatePage />, index: true },
              { path: 'NewItem', element: <ProductCreatePage /> },
              { path: 'EditItem/:id', element: <ProductEditPage /> },
            ],
          },
        ],
      },

      {
        path: 'masters',
        children: [
          { element: <AddressPurposePage />, index: true },
          {
            path: 'globalMasters',
            children: [
              { element: <AddressPurposePage />, index: true },
              { path: 'AddressPurpose', element: <AddressPurposePage /> },
              { element: <BankAccountTypePage />, index: true },
              { path: 'BankAccountType', element: <BankAccountTypePage /> },
              { element: <BankNamePage />, index: true },
              { path: 'BankName', element: <BankNamePage /> },
              { element: <BloodGroupPage />, index: true },
              { path: 'BloodGroup', element: <BloodGroupPage /> },
              { element: <BusinessUnitPage />, index: true },
              { path: 'BusinessUnit', element: <BusinessUnitPage /> },
              { element: <CertificatePage />, index: true },
              { path: 'Certificates', element: <CertificatePage /> },              { element: <CertificateTypePage />, index: true },
              { path: 'CertificateType', element: <CertificateTypePage /> },
              { element: <ContractTypePage />, index: true },
              { path: 'ContractType', element: <ContractTypePage /> },
              { element: <DepartmentPage />, index: true },
              { path: 'Department', element: <DepartmentPage /> },
              { element: <DesignationPage />, index: true },
              { path: 'Designation', element: <DesignationPage /> },
              { element: <DeliveryUnitPage />, index: true },
              { path: 'DeliveryUnit', element: <DeliveryUnitPage /> },
              { element: <DomainPage />, index: true },
              { path: 'Domain', element: <DomainPage /> },

              { element: <EmployementTypePage />, index: true },
              { path: 'EmployementType', element: <EmployementTypePage /> },
              { element: <GenderPage />, index: true },
              { path: 'Gender', element: <GenderPage /> },
              { element: <GoodPage />, index: true },
              { path: 'Good', element: <GoodPage /> },
              { element: <HSNSACPage />, index: true },
              { path: 'HsnSac', element: <HSNSACPage /> },
              { element: <ItemConditionPage />, index: true },
              { path: 'ItemCondition', element: <ItemConditionPage /> },
              { element: <ImpQuePage />, index: true },
              { path: 'Questions', element: <ImpQuePage /> },
              { element: <InstitutePage />, index: true },
              { path: 'Institute', element: <InstitutePage /> },
              { element: <InterviewLevelPage />, index: true },
              { path: 'InterviewLevel', element: <InterviewLevelPage /> },
              { element: <InterviewStatusPage />, index: true },
              { path: 'InterviewStatus', element: <InterviewStatusPage /> },
              { element: <ItemTypePage />, index: true },
              { path: 'ItemType', element: <ItemTypePage /> },
              { path: 'ItemCategoryMst', element: <ItemCategoryPage /> },
              { element: <ItemCategoryPage />, index: true },
              { element: <LanguagePage />, index: true },
              { path: 'Language', element: <LanguagePage /> },
              { element: <MaritalStatusPage />, index: true },
              { path: 'MaritalStatus', element: <MaritalStatusPage /> },
              { element: <MeetingPlatformPage />, index: true },
              { path: 'MeetingPlatform', element: <MeetingPlatformPage /> },

              { path: 'NameTitle', element: <NameTitlePage /> },
              { element: <OrganizationTypePage />, index: true },
              { path: 'OrganizationType', element: <OrganizationTypePage /> },
              { element: <PositionLevelPage />, index: true },
              { path: 'PositionLevel', element: <PositionLevelPage /> },
              { element: <PracticePage />, index: true },
              { path: 'Practice', element: <PracticePage /> },
              { element: <ProjectStatusPage />, index: true },
              { path: 'ProjectStatus', element: <ProjectStatusPage /> },
              { element: <ProjectTypeListPage />, index: true },
              { path: 'PTList', element: <ProjectTypeListPage /> },
              { element: <QualTypePage />, index: true },
              { path: 'QualificationType', element: <QualTypePage /> },
              { element: <QualificationPage />, index: true },
              { path: 'Qualification', element: <QualificationPage /> },
              { element: <QualificationStreamsPage />, index: true },
              { path: 'QualificationStreams', element: <QualificationStreamsPage /> },
              { element: <RelationshipPage />, index: true },
              { path: 'Relationship', element: <RelationshipPage /> },
              { element: <ReasonPage />, index: true },
              { path: 'Reason', element: <ReasonPage /> },
              { element: <ReligionPage />, index: true },
              { path: 'Religion', element: <ReligionPage /> },
              { element: <ReqStatusPage />, index: true },
              { path: 'ReqStatus', element: <ReqStatusPage /> },
              { element: <RevenueTypePage />, index: true },
              { path: 'RevenueType', element: <RevenueTypePage /> },
              { element: <RolePage />, index: true },
              { path: 'Role', element: <RolePage /> },
              { element: <ServiceCategoryPage />, index: true },
              { path: 'ServiceCategory', element: <ServiceCategoryPage /> },
              { element: <SkillCategoryPage />, index: true },
              { path: 'SkillCategory', element: <SkillCategoryPage /> },
              { element: <SkillListPage />, index: true },
              { path: 'SkillList', element: <SkillListPage /> },

              { element: <SourceOfHirePage />, index: true },
              { path: 'SourceOfHire', element: <SourceOfHirePage /> },
              { element: <TDSPage />, index: true },
              { path: 'TDS', element: <TDSPage /> },
              { element: <TransactionTypePage />, index: true },
              { path: 'TransactionType', element: <TransactionTypePage /> },
              { element: <UniversityPage />, index: true },
              { path: 'University', element: <UniversityPage /> },
              { path: 'UniqueIdentityDoc', element: <UniqueIdentityDocPage /> },
              { element: <UOMPage />, index: true },
              { path: 'UOM', element: <UOMPage /> },
              { element: <VisaNamePage />, index: true },
              { path: 'VisaName', element: <VisaNamePage /> },
              { element: <VisaTypePage />, index: true },
              { path: 'VisaType', element: <VisaTypePage /> },
              { element: <WorkModePage />, index: true },
              { path: 'WorkMode', element: <WorkModePage /> },
            ],
          },
          {
            path: 'localMasters',
            children: [
              { element: <DeclineReasonPage />, index: true },
              { path: 'DeclineReason', element: <DeclineReasonPage /> },
              { element: <OfferWithrawReasonPage />, index: true },
              { path: 'OfferWithdrawReason', element: <OfferWithrawReasonPage /> },
              { element: <EmpGradePage />, index: true },
              { path: 'EmpGrade', element: <EmpGradePage /> },
              { element: <InspectionParameterPage />, index: true },
              { path: 'InspectionParameter', element: <InspectionParameterPage /> },

              { element: <InterviewCancelReasonPage />, index: true },
              { path: 'InterviewCancelReason', element: <InterviewCancelReasonPage /> },
              { path: 'Modules', element: <ModulesPage /> },
              { element: <ModulesPage />, index: true },
              { element: <NameTitlePage />, index: true },
              { element: <NegativeFeedbackPage />, index: true },
              { path: 'NegativeFeedback', element: <NegativeFeedbackPage /> },
              { element: <RecommendationPage />, index: true },
              { path: 'Recommendation', element: <RecommendationPage /> },
              { element: <ReportsPage />, index: true },
              { path: 'Reports', element: <ReportsPage /> },
              { element: <RoleLevelPage />, index: true },
              { path: 'RoleLevel', element: <RoleLevelPage /> },
              { element: <ScreensPage />, index: true },
              { path: 'Screens', element: <ScreensPage /> },
              { element: <ServicePage />, index: true },
              { path: 'Service', element: <ServicePage /> },
              { element: <ServicePlanPage />, index: true },
              { path: 'ServicePlan', element: <ServicePlanPage /> },
              { element: <SubInventoryPage />, index: true },
              { path: 'SubInventory', element: <SubInventoryPage /> },
            ],
          },
          { path: 'ListClient', element: <ClientListPage /> },
          { path: 'ClientMst', element: <ClientMstPage /> },
          { path: 'EditClient/:id', element: <ClientEditPage /> },
          { path: 'DetailClient/:id', element: <ClientDetailPage /> },
          { path: 'ListVendor', element: <VendorListPage /> },
          { path: 'VendorMst', element: <VendorMstPage /> },
          { path: 'EditVendor/:id', element: <VendorEditPage /> },
          { path: 'DetailVendor/:id', element: <VendorDetailPage /> },
        ],
      },

     
      {
        path: 'super-admin',
        children: [
          { element: <RolePermissionListPage />, index: true },
          { path: 'role-permission', element: <RolePermissionListPage /> },
          { path: 'role-permission/new', element: <RolePermissionCreatePage /> },
          { path: 'role-permission/editRoleperm/:id', element: <RolePermissionEditPage /> },
          { path: 'role-permission/detailRoleperm/:id', element: <RolePermissionDetailPage /> },
          { path: 'UserMst', element: <UserListView /> },
          { path: 'UserForm', element: <UserForm /> },
          { path: 'UserRoleAssign', element: <UserRoleAssignPage /> },
        ],
      },
      {
        path: 'ManagerApproval',
        children: [
          { element: <CanReqApprovalPage />, index: true },

          { element: <MaterialReqApprovalPage />, index: true },
          { path: 'MaterialReqApproval', element: <MaterialReqApprovalPage /> },
          { path: 'PendingCanReq', element: <PendingCanReqApprovalPage /> },
          { path: 'ApprovedCanReq', element: <ApproveCanReqPage /> },
          { path: 'RejectedCanReq', element: <RejectCanReqPage /> },
          { path: 'PendingMatReq', element: <PendingMatReqApprovalPage /> },
          { path: 'ApprovedMatReq', element: <ApproveMatReqApprovalPage /> },
          { path: 'RejectedMatReq', element: <RejectedMatReqApprovalPage /> },
          { path: 'PurReqApproval', element: <PurchaseReqApprovalPage /> },
          { path: 'PenPurReq', element: <PendingPurReqApprovalPage /> },
          { path: 'ApprovePurReq', element: <ApproveCanReqPage /> },
          { path: 'RejectPurReq', element: <RejectCanReqPage /> },
          { path: 'PendingCancelCanReq', element: <PendingCancelCanReq /> },
          { path: 'RejectedCancelCanReq', element: <RejectedCancelCanReq /> },
          { path: 'ApprovedCancelCanReq', element: <ApprovedCancelCanReq /> },
          {
            path: 'CandidateRequisitionApproval',
            children: [
              { path: 'CanReqApproval', element: <CanReqApprovalPage /> },
              { path: 'CancelCanReq', element: <CancelCanReqPage /> },
            ],
          },
        ],
      },
      {
        path: 'taginterview',
        children: [{ element: <ResultFeedbackTagPage />, index: true }],
      },
      {
        path: 'interview',
        children: [{ element: <AssignedInterviewListPage />, index: true }],
      },
      // {
      //   path: 'employee',
      //   children: [
      //     { element: <EmployeeListPage />, index: true },
      //     { path: 'ListEmployee', element: <EmployeeListPage /> },
      //     { path: 'NewEmployee', element: <EmployeeCreatePage /> },
      //     { path: 'EditEmployee/:id', element: <EmployeeEditPage /> },
      //   ]
      // },
      {
        path: 'client',
        children: [],
      },
      {
        path: 'project',
        children: [
          { element: <ProjectListPage />, index: true },
          { path: 'projectlist', element: <ProjectListPage /> },
          { path: 'projectform', element: <ProjectFormPages /> },
          { path: ':id/edit', element: <ProjectEditPage /> },
          { path: ':id/view', element: <ProjectDetailPage /> },
          { path: 'projectallocationform', element: <ProjectAllocationPage /> },
        ],
      },
      {
        path: 'vendor',
        children: [{ element: <VendorListPage />, index: true }],
      },


     // My Requisitions
      {
        path: 'MyRequisitions',
        children: [
      // candidate Req
          { path: 'NewRequirement', element: <CanReqCreateP /> },
          { element: <CanReqEditPag/>, index: true },
          { path: 'EditReq/:id', element: <CanReqEditPag /> },
          { element: <CanReqReusePag />, index: true },
          { path: 'ReuseReq/:id', element: <CanReqReusePag /> },
          { element: <CanReqDetailsPage />, index: true },
          { path: 'DetailsReq/:id', element: <CanReqDetailsPage /> },
          { element: <ReqListPag />, index: true },
          { path: 'RequirementList', element: <ReqListPag /> },
          { element: <TagListPage />, index: true },

          { path: 'CanReqTagList', element: <TagListPage /> },
          { element: <CandidateViewListPages />, index: true },
          { path: 'CandidateStatusViewList/:id', element: <CandidateViewListPages /> },
          { element: <ManagerListP />, index: true },
          { path: 'CanReqManagerList', element: <ManagerListP/> },
          { element: <ManagerCandidateViewListP/>, index: true },
          { path: 'CandidateStatusViewMgrList/:id', element: <ManagerCandidateViewListP /> },
         { path: 'CanReqManagerList', element: <ManagerListP /> },


      
            { element: <NewJdPage />, index: true },
            { path: 'NewJd', element: <NewJdPage /> },
            { element: <PastJdListPage />, index: true },
            { path: 'PastJd', element: <PastJdListPage /> },
            { element: <EditJdPage />, index: true },
            { path: 'EditJd/:id', element: <EditJdPage /> },
            { element: <ReuseJdPage />, index: true },
            { path: 'ReuseJd/:id', element: <ReuseJdPage /> },
            { element: <DetailsJdPage />, index: true },
            { path: 'DetailsJd/:id', element: <DetailsJdPage /> },

          


          // material req
         { path: 'list', element: <ListPage /> },
         { path: ':id', element: <DetailsPage /> },
         { path: 'NewItem', element: <CreatePage /> },
         { path: 'EditItem/:id', element: <EditPage /> },
         { path: ':req', element: <ItemReq /> },

         { path: 'ItemRequisition', element: <ItemCategory /> },

          // Purchase Req

        //  { element: <PRPage />, index: true },
          { path: 'PR', element: <PRPage /> },

          // { element: <PhysicalInventoryListP />, index: true },
          // { path: 'PhysicalInventoryList/:id', element: <PhysicalInventoryListP /> },
        
          { element: <PurchaseRequisitionsP />, index: true },
          { path: 'purchasereq', element: <PurchaseRequisitionsP /> },

          { element: <AllItemRequisitionsTagListP />, index: true },
          { path: 'AllItemReqTag', element: <AllItemRequisitionsTagListP /> },
          
        ],
      },





 


      {
        path: 'ToDo',
        children: [
          { element: <ToDoPage />, index: true },
          { path: 'TagToDo', element: <ToDoPage /> },
     
        ],
      },


      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'NewItem', element: <ProductCreatePage /> },
          { path: 'EditItem/:id', element: <ProductEditPage /> },
          { path: ':req', element: <ItemReqPage /> },
          // { path: ':itemcategorymst', element: <ItemCategoryPage /> },
          { element: <ItemCategoryPage2 />, index: true },
          { path: 'ItemRequisition', element: <ItemCategoryPage2 /> },
          { element: <InvTransactionPage />, index: true },
          { path: 'InvTransaction', element: <InvTransactionPage /> },
          { element: <InvTransactionListPage />, index: true },
          { path: 'InvTransactionList', element: <InvTransactionListPage /> },
          { element: <AssetsListPage />, index: true },
          { path: 'AssetList', element: <AssetsListPage /> },
          { element: <AssetsTrackerListPage />, index: true },
          { path: 'AssetTrackerList', element: <AssetsTrackerListPage /> },
          { element: <InvOnHandListPage />, index: true },
          { path: 'InvOnHandList', element: <InvOnHandListPage /> },
          { element: <PhysicalInventoryListPage />, index: true },
          { path: 'PhysicalInventoryList/:id', element: <PhysicalInventoryListPage /> },
          { element: <AllPhysicalInvListPage />, index: true },
          { path: 'AllPhysicalInventoryList', element: <AllPhysicalInvListPage /> },
          { element: <AssetsTrackerAdminListPage />, index: true },
          { path: 'AssetTrackerAdminList', element: <AssetsTrackerAdminListPage /> },
          { element: <InspectionParameterMasterListPage />, index: true },
          { path: 'InspectionParameterMasterList', element: <InspectionParameterMasterListPage /> },
          { element: <InspectionPage />, index: true },
          { path: 'InspectionList', element: <InspectionPage /> },
        ],
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'post',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':title', element: <BlogPostPage /> },
          { path: ':title/edit', element: <BlogEditPostPage /> },
          { path: 'new', element: <BlogNewPostPage /> },
        ],
      },
      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      {
        path: 'tour',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
        ],
      },
      { path: 'file-manager', element: <FileManagerPage /> },
      { path: 'mail', element: <MailPage /> },
      { path: 'chat', element: <ChatPage /> },
      // { path: 'calendar', element: <CalendarPage /> },
      // { path: 'calendarform', element: <CalendarFormPage />},
      // { path: 'calenderUpdate/:arg', element: <CalenderUpdate /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
