import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { m } from 'framer-motion';
// @mui
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import axios from 'axios';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { LoadingScreen } from 'src/components/loading-screen';
import { getCurrentProject, getCurrentServer, getMandEndPoint } from 'src/helper/server-address';
// assets
import { ForbiddenIllustration } from 'src/assets/illustrations';
// components
import { MotionContainer, varBounce } from 'src/components/animate';
import { useAuthContext } from '../hooks';

// ----------------------------------------------------------------------

export default function RoleBasedGuard({ hasContent, roles, children, currentPage, sx }) {
  // Logic here to get current user role
  const { user } = useMockedUser();
  const {userRoles} = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);

  
  
  useEffect(() => {
    
    if(roles.length > 0 ){
      setIsLoading(false);
    }
    
  }, [roles, userRoles]);


const userRoleIds = userRoles.map((role) => role.roleId);
const matchedRoles = roles.filter((role) => userRoleIds.includes(role.roleId));
const hasFullAccess = matchedRoles.some((role) => role.fullAccess);



const canAccessRestrictedPages =
    currentPage === "list" || currentPage === "view";


  // const isPermissionDenied =
  //   roles && !roles.some((role) => userRoles.some((currRole) => currRole.roleId === role?.roleId?.roleId));

    if (isLoading) {
      // Render loading state
      console.log("Showing LOading Screen");
      
      return <LoadingScreen />;
    }

  if (matchedRoles.length === 0) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center', ...sx }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            You do not have permission to access this page
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration
            sx={{
              height: 260,
              my: { xs: 5, sm: 10 },
            }}
          />
        </m.div>
      </Container>
    ) : null;
  }
  if (!hasFullAccess && !canAccessRestrictedPages && !isLoading) {
    return hasContent ? (
      <Container component={MotionContainer} sx={{ textAlign: 'center', ...sx }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            Permission Denied
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            You do not have permission to access this page
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration
            sx={{
              height: 260,
              my: { xs: 5, sm: 10 },
            }}
          />
        </m.div>
      </Container>
    ) : null;
  }

  return <>{React.cloneElement(children, { fullAccess: hasFullAccess })}</>;
}

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  hasContent: PropTypes.bool,
  roles: PropTypes.array,
  sx: PropTypes.object,
  currentPage: PropTypes.string,
};
