import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import FormHelperText from '@mui/material/FormHelperText';
// Upload components
import { UploadAvatar, Upload, UploadBox } from '../upload'; // Assuming UploadAvatar, Upload, and UploadBox are your custom components

// ----------------------------------------------------------------------

// RHFUploadAvatar: Handles avatar upload and shows accepted file types and max size
export function RHFUploadAvatar({ name, acceptFile, maxSize = 3 * 1024 * 1024, helperText, file, onDrop, onDelete, ...other }) {
  const { control } = useFormContext();

  // Function to format max size for display
  const formatSize = (size) => {
    if (size >= 1024 * 1024) return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    if (size >= 1024) return `${(size / 1024).toFixed(2)} KB`;
    return `${size} bytes`;
  };

  // Generate Accepted File Types Info dynamically based on the accept prop
  const getAcceptedFileTypes = () => {
    const acceptedTypes = (acceptFile || '').split(',').map((type) => {
      switch (type.trim()) {
        case 'image/*':
          return 'Images (e.g., JPG, JPEG, PNG)';
        case 'application/pdf':
          return 'PDF';
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          return 'DOC/DOCX';
        default:
          return type.split('/')[1]?.toUpperCase() || type; // Default case to handle other types
      }
    });

    return acceptedTypes.join(', ');
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          {/* Custom UploadAvatar Component */}
          <UploadAvatar
            error={!!error}
            file={file || field.value} // Use either previewUrl or file from field value
            accept={acceptFile} // Dynamically passed accepted file types
            maxSize={maxSize} // Dynamically passed max file size
            onDrop={onDrop} // Custom onDrop handler
            onDelete={onDelete} // Custom onDelete handler
            {...other} // Additional props can be passed
          />

          {/* Error message */}
          {!!error && (
            <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
              {error.message}
            </FormHelperText>
          )}

          {/* Accepted file types */}
          {/* <FormHelperText sx={{ px: 2 }}>
            <strong>Accepted Files:</strong> {getAcceptedFileTypes()}
          </FormHelperText> */}

          {/* File size information */}
          {/* {maxSize && (
            <FormHelperText sx={{ px: 2 }}>
              <strong>Max File Size:</strong> {formatSize(maxSize)} 
            </FormHelperText>
          )} */}

          {/* Optional helper text (e.g., for additional file upload instructions) */}
          {helperText && (
            <FormHelperText sx={{ px: 2 }}>
              {helperText}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}

RHFUploadAvatar.propTypes = {
  name: PropTypes.string.isRequired, // Name of the field in the form
  acceptFile: PropTypes.string.isRequired, // Accepted file types (e.g., "image/*,application/pdf")
  maxSize: PropTypes.number, // Max file size in bytes (default 3MB)
  helperText: PropTypes.string, // Optional helper text
  onDrop: PropTypes.func, // Optional handler for file drop
  onDelete: PropTypes.func, // Optional handler for file deletion
  file: PropTypes.string, // Optional file preview (e.g., image URL or file path)
};

// ----------------------------------------------------------------------

// RHFUploadBox: Handles file upload and shows accepted file types and max size
export function RHFUploadBox({ name, acceptFile, maxSize = 3 * 1024 * 1024, helperText, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <UploadBox
          acceptFile={acceptFile}
          files={field.value}
          error={!!error}
          helperText={
            (!!error || helperText) && (
              <FormHelperText error={!!error} sx={{ px: 2 }}>
                {error ? error?.message : helperText}
              </FormHelperText>
            )
          }
          maxSize={maxSize}
          {...other}
        />
      )}
    />
  );
}

RHFUploadBox.propTypes = {
  helperText: PropTypes.string,
  name: PropTypes.string.isRequired,
  acceptFile: PropTypes.string.isRequired,
  maxSize: PropTypes.number,
};

// ----------------------------------------------------------------------

// RHFUpload: Handles general file upload (single or multiple files) and shows accepted file types and max size
export function RHFUpload({ name, multiple, acceptFile, maxSize = 3 * 1024 * 1024, helperText, ...other }) {
  const { control } = useFormContext();

  // Function to format max size for display
  const formatSize = (size) => {
    if (size >= 1024 * 1024) return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    if (size >= 1024) return `${(size / 1024).toFixed(2)} KB`;
    return `${size} bytes`;
  };

  // Generate Accepted File Types Info
  const getAcceptedFileTypes = () => {
    const extensions = (acceptFile || '').split(',').map((type) => {
      switch (type.trim()) {
        case 'image/*':
          return 'Images (e.g., JPG, PNG, GIF)';
        case 'application/pdf':
          return 'PDF';
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          return 'DOC/DOCX';
        default:
          return type.split('/')[1]?.toUpperCase() || type;
      }
    });
    return extensions.join(', ');
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) =>
        multiple ? (
          <div>
            <Upload
              multiple
              accept={acceptFile}
              files={field.value}
              error={!!error}
              helperText={
                (!!error || helperText) && (
                  <FormHelperText error={!!error} sx={{ px: 2 }}>
                    {error ? error?.message : helperText}
                  </FormHelperText>
                )
              }
              {...other}
            />
          </div>
        ) : (
          <div>
            <Upload
              accept={acceptFile}
              file={field.value}
              error={!!error}
              helperText={
                (!!error || helperText) && (
                  <FormHelperText error={!!error} sx={{ px: 2 }}>
                    {error ? error?.message : helperText}
                  </FormHelperText>
                )
              }
              {...other}
            />
          </div>
        )
      }
    />
  );
}

RHFUpload.propTypes = {
  helperText: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  acceptFile: PropTypes.string.isRequired,
  maxSize: PropTypes.number,
};
