import PropTypes from 'prop-types';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import numeral from 'numeral';
// utils
import { fData } from 'src/utils/format-number';
//
import { fileData } from '../file-thumbnail';

// ----------------------------------------------------------------------

export default function RejectionFiles({ fileRejections, maxSize, minSize, acceptFile }) {
  if (!fileRejections?.length) {
    return null;
  }

  // console.log(acceptFile,"acceptedFiles");
  // console.log(fileRejections,"fileRejections");
  // console.log(numeral(maxSize).format('0.0 b'),"maxSize");

  const getFileExtensions = () => {
    const extensions = new Set();

    Object.entries(acceptFile).forEach(([fileType]) => {
      switch (fileType) {
        case 'application/pdf':
          extensions.add('pdf');
          break;
        case 'application/msword':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          extensions.add('doc/docx');
          break;
        case 'application/vnd.ms-excel':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          extensions.add('xls/xlsx');
          break;
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
        case 'application/vnd.ms-powerpoint':
          extensions.add('ppt/pptx');
          break;
        case 'text/plain':
          extensions.add('txt');
          break;
        // case "image/*":
        //   extensions.add('png/jpg/jpeg/gif/avif/svg');
        //   break;
        case 'image/*':
          extensions.add('png');
          extensions.add('jpg');
          extensions.add('jpeg');
          extensions.add('gif');
          extensions.add('bmp');
          extensions.add('webp');
          extensions.add('tiff');
          extensions.add('svg');
          extensions.add('heic');
          extensions.add('avif');
          extensions.add('ico');
          extensions.add('jfif');
          break;

        default:
          break;
      }
    });

    return Array.from(extensions).join(', ');
  };

  const truncateFileName = (fileName, maxLength) => {
    if (fileName.length <= maxLength) return fileName;
    const start = fileName.substring(0, maxLength / 2);
    const end = fileName.substring(fileName.length - maxLength / 2);
    return `${start}...${end}`;
  };

  const getErrorMessage = (error) => {
    switch (error.code) {
      case 'file-too-large':
        return `- File is larger than ${numeral(maxSize).format('0.0 b')}.`;
      case 'file-too-small':
        return `- File is smaller than ${numeral(minSize).format('0.0 b')}.`;
      case 'file-invalid-type':
        return `- Invalid file type. File type must be ${getFileExtensions()}.`;
      default:
        return `- ${error.message}`;
    }
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
        borderColor: (theme) => alpha(theme.palette.error.main, 0.24),
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = fileData(file);

        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
              {truncateFileName(path, 40)} - {size ? fData(size) : ''}
            </Typography>

            {errors.map((error) => (
              <Box key={error.code} component="span" sx={{ typography: 'caption' }}>
                {getErrorMessage(error)}
              </Box>
            ))}
          </Box>
        );
      })}
    </Paper>
  );
}

RejectionFiles.propTypes = {
  fileRejections: PropTypes.array,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  acceptFile: PropTypes.object,
};
