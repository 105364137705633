import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Box, Typography } from '@mui/material';
import Iconify from '../iconify';
import RejectionFiles from './errors-rejection-files';

export default function UploadBox({
  placeholder,
  helperText,
  maxSize,
  error,
  disabled,
  acceptFile,
  avatarUpload,
  sx,
  fileRejections,
  ...other
}) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, acceptedFiles } = useDropzone({
    disabled,
    maxSize: maxSize || 3 * 1024 * 1024, // 3MB (strictly 3MB)
    accept: acceptFile ? Object.keys(acceptFile).join(',') : '', // Dynamically accept file types based on the acceptFile prop
    ...other,
  });

  const hasError = isDragReject || error;

  // Function to format max size
  const formatSize = (size) => {
    if (size >= 1024 * 1024) return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    if (size >= 1024) return `${(size / 1024).toFixed(2)} KB`;
    return `${size} bytes`;
  };

  // Generate Accepted File Types Info dynamically from acceptFile prop
  const getAcceptedFileTypes = () => {
    const fileTypeMap = {
      'application/pdf': 'PDF',
      'application/msword': 'DOC/DOCX',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOC/DOCX',
      'application/vnd.ms-excel': 'XLS/XLSX',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLS/XLSX',
      'image/*': 'Images (e.g., JPG, PNG, GIF)',
      'application/zip': 'ZIP',
      'text/plain': 'TEXT',
      'application/vnd.ms-powerpoint': 'PPT',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
      'application/vnd.openxmlformats-officedocument.presentationml.slideshow': 'PPSX',
    };

    const acceptedTypes = Object.keys(acceptFile || {}).map((type) => fileTypeMap[type] || type.split('/')[1]?.toUpperCase() || type);
    
    return [...new Set(acceptedTypes)].join(', ');
  };

  return (
    <Box sx={{ width: 1, position: 'relative', ...sx }}>
      <Box
        {...getRootProps()}
        sx={{
          m: 0.5,
          width: 64,
          height: 64,
          flexShrink: 0,
          display: 'flex',
          borderRadius: 1,
          cursor: 'pointer',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'text.disabled',
          bgcolor: avatarUpload && acceptedFiles.length ? 'transparent' : 'white', // Changed to white
          border: avatarUpload
            ? 'solid 2px rgba(145, 158, 171, 0.2)'
            : 'solid 1px rgba(145, 158, 171, 0.2)',
          '&:hover': {
            opacity: 0.72,
            border: 'solid 1px rgb(0, 0, 0)',
          },
          ...(isDragActive && {
            opacity: 0.72,
          }),
          ...(disabled && {
            opacity: 0.48,
            pointerEvents: 'none',
          }),
          ...(hasError && {
            color: 'error.main',
            bgcolor: 'error.lighter',
            borderColor: 'error.light',
          }),
          ...sx,
        }}
      >
        <input {...getInputProps()} />
        {avatarUpload && acceptedFiles.length ? (
          <img
            src={URL.createObjectURL(acceptedFiles[0])}
            alt="Avatar"
            style={{ width: '100%', height: '100%', borderRadius: '50%' }}
          />
        ) : (
          placeholder || <Iconify icon="eva:cloud-upload-fill" width={28} />
        )}
      </Box>

      <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
        <Typography variant="caption">
          <strong>Accepted Files:</strong> {getAcceptedFileTypes()}
        </Typography>
        {maxSize && (
          <Typography variant="caption">
            <strong>Max File Size:</strong> {formatSize(3 * 1024 * 1024)} {/* Strictly 3MB */}
          </Typography>
        )}
      </Box>

      {helperText && helperText}

      <RejectionFiles fileRejections={fileRejections} maxSize={maxSize || 3 * 1024 * 1024} acceptFile={acceptFile} />
    </Box>
  );
}

UploadBox.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.node,
  placeholder: PropTypes.node,
  sx: PropTypes.object,
  maxSize: PropTypes.number,
  acceptFile: PropTypes.object, // Dynamically accepts the file types
  fileRejections: PropTypes.array,
  avatarUpload: PropTypes.bool, // Flag for Avatar upload
};
